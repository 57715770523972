export const detectMobileDevice = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ]
    return toMatch.some((toMatchItem) => {
        return navigator?.userAgent?.match(toMatchItem)
    })
}

export const downloadMetamask = () => {
    const isMobile = detectMobileDevice()
    if (isMobile) {
        window.open(
            `https://metamask.app.link/dapp/${window?.location?.href}`,
            "_self"
        )
    } else {
        window.open("https://metamask.io", "_blank")
    }
}