import * as React from "react";

const SvgArrowUp = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="16" height="16" rx="4" fill="#C8DC3C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.64646 3.14645C7.84172 2.95118 8.15831 2.95118 8.35357 3.14645L11.8536 6.64645C11.9966 6.78945 12.0393 7.0045 11.962 7.19134C11.8846 7.37818 11.7022 7.5 11.5 7.5H10V12.5C10 12.7761 9.77616 13 9.50001 13H6.50001C6.22387 13 6.00001 12.7761 6.00001 12.5V7.5H4.50001C4.29778 7.5 4.11547 7.37818 4.03807 7.19134C3.96068 7.0045 4.00346 6.78945 4.14646 6.64645L7.64646 3.14645ZM5.70712 6.5H6.50001C6.77616 6.5 7.00001 6.72386 7.00001 7V12H9.00001V7C9.00001 6.72386 9.22387 6.5 9.50001 6.5H10.2929L8.00001 4.20711L5.70712 6.5Z"
      fill="#141414"
    />
  </svg>
);

export default SvgArrowUp;
