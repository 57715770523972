import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { CircularProgress } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Close } from '@mui/icons-material';
import { RenderMode } from 'mapillary-js';
import { useAppContext } from '../../lib/context/app-context';
// import { LeftMenu, RightMenu } from '../../v2Components';

const Mapillary = require('mapillary-js');
const provider = new Mapillary.GraphDataProvider({
  endpoint: `${process.env.REACT_APP_BACKEND_URL}api/streetImagery/`,
});
const MapillaryViewer = ({
  imageId,
  map,
  toggleMapillary,
  setToggleMapillary,
}) => {
  const containerRef = useRef(null);
  const sequencePlayerRef = useRef(true);
  const [mapilary, setMapilary] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = React.useState(false);
  const { setCurrentImageId } = useAppContext()

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 20);
  }, [toggleMapillary]);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN;
    let mapillaryViewer = new Mapillary.Viewer({
      renderMode: RenderMode.Letterbox,
      // 'accessToken': 'MLY|6690365790977664|4295a0a62411dd186aa0a0d3704a2d33',
      container: containerRef.current,
      imageId: imageId,
      imageTiling: false,
      // component: {
      //   cover: false,
      // },

      dataProvider: provider,
    });
    mapillaryViewer.on('dataloading', function (e) {
      setIsLoading(e.loading);
    });
    mapillaryViewer.on('load', function (e) {
      setIsMapLoaded(true);
    });
    var featureId = null;
    mapillaryViewer.on('image', function (e) {
      if (sequencePlayerRef.current) {
        const query = map.queryRenderedFeatures({
          sourceID: 'mapillary-images',
        });
        if (featureId) {
          map.removeFeatureState({
            source: 'mapillary',
            sourceLayer: 'image',
            id: featureId,
          });
        }
        for (let i = 0; i < query.length; i++) {
          if (sequencePlayerRef.current) {
            if (query[i].properties.id == e.image.id) {
              featureId = query[i].id;
              map.setFeatureState(
                {
                  source: 'mapillary',
                  sourceLayer: 'image',
                  id: featureId,
                },
                {
                  clicked: true,
                }
              );
            }
          } else {
            break;
          }
        }
      }
    });
    setMapilary(mapillaryViewer);
  }, [map]);

  useEffect(() => {
    if (imageId && mapilary && isMapLoaded) {
      sequencePlayerRef.current = true
      mapilary.moveTo(imageId);
    }
  }, [imageId, mapilary]);

  const handleCloseMappillary = () => {
    setCurrentImageId(null);
    setToggleMapillary(false);
    map.removeFeatureState({
      source: 'mapillary',
      sourceLayer: 'image',
      // id: featureId,
    });
    sequencePlayerRef.current = false
  }
  return (
    <div className={toggleMapillary ? 'App' : 'main-street-viewer'}>
      {isLoading && (
        <div
          className={
            toggleMapillary ? 'streetViewerLoadingFull' : 'streetViewerLoading'
          }
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'inherit',
            }}
          >
            <CircularProgress style={{ color: 'white' }} />
          </div>
        </div>
      )}
      <div
        ref={containerRef}
        id={toggleMapillary ? 'fullStreetViewer' : 'streetViewer'}
        style={
          toggleMapillary
            ? { minHeight: '94.5vh', height: 'auto' }
            : { position: 'relative' }
        }
        className={isLoading ? 'hideViewer' : ''}
      >
        {!toggleMapillary ? (
          <div
            onClick={handleCloseMappillary}
            style={{
              background: "rgba(0,0,0,0.5)",
              padding: "0px",
              height: '60px',
              width: '60px',
              position: 'absolute',
              top: 0, right: 0,
              cursor: 'pointer',
              display: "flex", justifyContent: "end", alignItems: "start",
              zIndex: 5,
              clipPath: "polygon(0 0, 100% 0, 100% 100%)"

            }}
          >
            <Close style={{ color: "#ffff", fontSize: "25px", marginTop: "5px", marginRight: "2px" }} />
          </div>
        ) : (
          <>
            {/* <LeftMenu />
            <RightMenu /> */}
          </>
        )}

        {!toggleMapillary ? (
          <div
            onClick={() => setToggleMapillary((val) => !val)}
            style={{
              background: 'rgba(0,0,0,0.5)',
              padding: '0px',
              height: '75px',
              width: '75px',
              position: 'absolute',
              top: 0,
              left: 0,
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'start',
              zIndex: 5,
              clipPath: "polygon(0 0, 0% 100%, 100% 0)"
            }}
          >

            <ExpandLessIcon
              style={{ color: '#ffff', fontSize: '40px', rotate: '-45deg' }}
            />
            {/* <Close
              style={{ color: '#ffff', fontSize: '30px' }}
            /> */}
          </div>
        ) : (
          <>
            {/* <LeftMenu />
            <RightMenu /> */}
          </>
        )}
      </div>
    </div>
  );
};

export default MapillaryViewer;
