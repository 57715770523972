import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    background: "rgba(0, 0, 0, 0.7)",
    color: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0px 1px 24px rgba(0, 0, 0, 0.1)",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  textStyle: {
    fontFamily: "Formular",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "32px",
  },
  textStyle1: {
    fontFamily: "Formular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "0.875rem",
    letterSpacing: "-0.05em",
    lineHeight: "160%",
  },
  button1: {
    border: "1px solid #FFFFFF",
    borderRadius: "8px",
    color: "#ffffff",
    fontFamily: "Formular",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "0.875rem",
    textTransform: "uppercase !important",
  },
  stepCountText: {
    fontFamily: "Formular",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "0.625 rem",
    lineHeight: "160%",
  },
  button2: {
    background: "#C8DC3C",
    borderRadius: "8px",
    fontFamily: "Formular",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "0.875rem",
    letterSpacing: "-0.05em",
    textTransform: "uppercase !important",
    color: "#141414",
    "&:hover": {
      background: "#C8DC3C",
    },
  },
  CloseIconDiv: {
    textAlign: "right",
  },
}));

export const Tooltip = ({
  index,
  step,
  tooltipProps,
  primaryProps,
  backProps,
  skipProps,
}) => {
  const classes = useStyles();

  const skipTutorial = (e) => {
    localStorage.setItem("tutorial_skipped", true);
    skipProps.onClick(e);
  };
  const doneClicked = (e) => {
    localStorage.setItem("tutorial_completed", true);
    primaryProps.onClick(e);
  };
  const closeIconClicked = (e) => {
    skipProps.onClick(e);
  };

  return (
    <Card
      {...tooltipProps}
      className={classes.mainGrid}
      style={{ marginTop: index < 5 && index > 1 ? "50px" : "0px" }}
    >
      {index === 0 && (
        <div className={classes.CloseIconDiv}>
          <CloseIcon style={{ cursor: "pointer" }} onClick={closeIconClicked} />
        </div>
      )}
      <CardContent style={{ padding: "8px" }}>
        {index > 0 && (
          <Typography
            gutterBottom
            variant="p"
            className={classes.stepCountText}
            style={{ textAlign: "left" }}
            component="p"
          >
            {index} of 5
          </Typography>
        )}
        <Typography
          gutterBottom
          variant="h5"
          className={classes.textStyle}
          style={{ textAlign: index < 1 ? "center" : "left" }}
          component="h2"
        >
          {step.title}
        </Typography>
        <Typography
          variant="body2"
          className={classes.textStyle1}
          style={{ textAlign: index < 1 ? "center" : "left" }}
        >
          {step.content}
        </Typography>
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: index < 1 ? "center" : "flex-start",
        }}
      >
        {index < 5 && (
          <Button
            className={classes.button1}
            onClick={skipTutorial}
            variant="text"
          >
            Skip Tutorial
          </Button>
        )}
        {index !== 5 && (
          <Button className={classes.button2} variant="text" {...primaryProps}>
            {index > 0 ? "Next" : "Let's go!"}
          </Button>
        )}
        {index === 5 && (
          <Button
            className={classes.button2}
            variant="text"
            onClick={doneClicked}
          >
            Start Navigating!
          </Button>
        )}
      </CardActions>
    </Card>
  );
};
