import * as React from 'react';
import { TextField, InputAdornment, Autocomplete, Box } from '@mui/material';
import { useTheme } from '@emotion/react';

export const SearchBox = ({
    suggestions,
    setSuggestions,
    searchHandler, showMenu
}) => {
    const [focus, setFocus] = React.useState(false)
    const theme = useTheme()
    return (
        showMenu ? <Box sx={{ bgcolor: focus ? "white" : theme.palette.theme_white.light, backdropFilter: "blur(12px)", pt: 2, px: 2 }}>
            <Autocomplete
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                freeSolo
                onInputChange={async (event, newInputValue) => {
                    const search = `https://api.mapbox.com/geocoding/v5/mapbox.places/${newInputValue}.json?access_token=${[
                        process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN,
                    ]}`;
                    const response = await fetch(search, {
                        method: 'GET',
                    }).then(response => response.json());
                    setSuggestions(
                        response.features.map((item, index) => ({
                            label: item.place_name,
                            id: index,
                            coordinates: item.geometry.coordinates,
                        })),
                    );
                }}
                onChange={(event, newValue) => {
                    searchHandler(newValue.label);
                }}
                id={`mapbox-geocode-search`}
                options={suggestions}
                fullWidth
                renderInput={params => (
                    <TextField
                        placeholder='What are you looking for?'
                        {...params}
                        fullWidth
                        variant='standard'
                        InputProps={{
                            ...params.InputProps,
                            sx: {
                                "&.MuiInput-root": { py: "12px" }
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src="/trend.webp" alt="trend-icon" style={{ height: "22px", }} />
                                </InputAdornment>
                            ),
                            ...(!params?.inputProps?.ref?.current?.value && {
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <img src="/search.webp" alt="search-icon" style={{ height: "14px" }} />
                                    </InputAdornment>)
                            }),
                        }}
                    />
                )}
            /> </Box>
            : <Box sx={{ pt: 4, px: 2, pb: 1, borderBottom: `1px solid ${theme.palette.gray.light}` }}>
                <img src="/trend.webp" alt="trend-icon" style={{ height: "22px", }} />
            </Box>


    );
};
