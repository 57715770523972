import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Switch from '@mui/material/Switch';
import CookieConsent, { getCookieConsentValue, resetCookieConsentValue } from 'react-cookie-consent';
import { Box, Button, Typography, Dialog } from '@mui/material';
import { COOKIE_URL } from '../../utils/Constants/constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles({
    modal: {
        backgroundColor: '#ffffff',
        color: '#141414',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        borderRadius: '5px',
        padding: '10px',
        boxShadow: '24',
    },
});

const bannerBtnStyle = { width: 0, height: 0, visibility: 'hidden', padding: 0, margin: 0, position: 'absolute' };

export const CookieSettings = ({ open, handleClose, handleConsentChange }) => {
    const classes = useStyles();
    const consent = getCookieConsentValue() ? JSON.parse(getCookieConsentValue()) : { ads: false, analytics: false };
    const [analyticsChecked, setAnalyticsChecked] = useState(Boolean(consent.analytics));
    const [adsChecked, setAdsChecked] = useState(Boolean(consent.ads));
    const [isSSR, setIsSSR] = useState(true);
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        setIsSSR(false);
    }, []);

    // useEffect(() => {
    //     setAnalyticsChecked(Boolean(consent.analytics));
    //     setAdsChecked(Boolean(consent.ads));
    // }, [consent]);

    // Handling Accept All
    async function handleCookieAccept() {
        setAnalyticsChecked(true);
        setAdsChecked(true);

        await handleConsentChange(true, true);

        let node = document.getElementById('cookiesAcceptNode');
        if (node) node.click();
        handleClose();
    }

    //Handling Reject All
    async function handleCookieReject() {
        resetCookieConsentValue('CookieConsent');
        await handleConsentChange(false, false);
        let node = document.getElementById('cookiesRejectNode');
        if (node) node.click();
        if (analyticsChecked) setAnalyticsChecked(false);
        if (adsChecked) setAdsChecked(false);
        handleClose();
    }

    const handleCloseModal = async () => {
        if (analyticsChecked && adsChecked) {
            handleCookieAccept();
        } else if (!analyticsChecked && !adsChecked) {
            handleCookieReject();
        } else {
            await handleConsentChange(adsChecked, analyticsChecked);
            let node = document.getElementById('cookiesAcceptNode');
            if (node) node.click();
            handleClose();
        }
    };

    return (
        <Dialog onClose={handleCloseModal} open={open} maxWidth="sm">
            <Box className={classes.modal}>
                <div style={{ visibility: 'hidden', width: '0' }}>
                    <CookieConsent
                        location="bottom"
                        acceptOnScroll={false}
                        overlay={false}
                        enableDeclineButton
                        buttonClasses={'cookie-button'}
                        visible={'show'}
                        extraCookieOptions={{ domain: '.nvg8.io' }}
                        buttonId={'cookiesAcceptNode'}
                        cookieValue={`{"ads": ${adsChecked}}`}
                        style={{ position: 'sticky' }}
                        buttonStyle={bannerBtnStyle}
                        declineButtonStyle={bannerBtnStyle}
                        buttonText={''}
                        debug={false}
                        expires={90}
                        declineButtonId={'cookiesRejectNode'}
                        declineCookieValue={`{"ads": ${adsChecked}}`}
                    ></CookieConsent>
                </div>
                <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
                    Your Cookie Settings
                </Typography>
                <Typography variant="body2" component="div" color={'#7F7F7F'} sx={{ marginTop: 1 }}>
                    We and third parties might set cookies on your browser when you visit our website. These cookies collect information
                    about how you use the website. You may choose to enable certain cookies using the options below. If you use other
                    browsers or devices, you may have to repeat your selections on each browser or device you use. For more information on
                    how we use cookies, please review our{' '}
                    <a
                        href={COOKIE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'underline', color: '#2e3131' }}
                    >
                        Cookies Policy
                    </a>
                </Typography>

                <Box flex={1} width={'100%'} flexDirection={'column'} mt={2}>
                    <Box flex={1} width={'100%'} justifyContent={'space-between'}>
                        <Typography variant="subtitle2" component="p">
                            Essential Cookies{' '}
                        </Typography>{' '}
                        <Typography variant="body2" component="p" color={'#7F7F7F'}>
                            Essential Cookies are necessary to allow the website to function properly. You can set your browser to block
                            Essential Cookies, but you may not be able to use all of the pages and features of our website if you do.
                        </Typography>
                    </Box>

                    <Box flex={1} mt={2} display={'flex'} flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
                        <Typography variant="subtitle2" component="h2">
                            {/* Analytics Cookies */}
                            <Typography variant="body2" component="p" color={'#7F7F7F'}>
                                Analytics Cookies inform us how visitors access and navigate our website. We use Analytics Cookies so that
                                we can improve the layout, accessibility, and content of the website.
                            </Typography>
                        </Typography>

                        {/* {!isSSR && (
                            <Switch
                                checked={analyticsChecked}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setAnalyticsChecked(e.target.checked);
                                }}
                                color={'secondary'}
                            />
                        )} */}
                    </Box>

                    <Box flex={1} mt={2} display={'flex'} flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
                        <Typography variant="subtitle2" component="h2">
                            Ad Cookies
                            <Typography variant="body2" component="p" color={'#7F7F7F'}>
                                “Advertising Cookies” are cookies that may be set through our site by our advertising partners. They may be
                                used by those companies to make advertising more relevant to you and to build a profile of your interests
                                and show you relevant adverts on other sites. They generally do not store personally identifiable
                                information, but are based on uniquely identifying your browser and internet device. If you do not allow
                                these cookies, you will experience less targeted advertising.
                            </Typography>
                        </Typography>

                        {!isSSR && (
                            <Switch
                                checked={adsChecked}
                                onChange={(e) => setAdsChecked(e.target.checked)}
                                color={'secondary'}
                            />
                        )}
                    </Box>
                </Box>

                <Box display={'flex'} flexDirection={largeScreen ? 'row' : 'column'} justifyContent={'flex-end'} width={'100%'} mt={1}>
                    <Button
                        variant="contained"
                        className={'cookieBannerBtn'}
                        color="success"
                        sx={{ margin: '0 1rem', backgroundColor: '#12c553' }}
                        onClick={() => handleCookieAccept()}
                    >
                        Accept All Cookies
                    </Button>
                    <Button
                        variant={'outlined'}
                        color="error"
                        className={'cookieBannerBtn'}
                        onClick={() => handleCookieReject()}
                        sx={{
                            backgroundColor: '#e84117',
                            color: 'white',
                            '&:hover': { backgroundColor: '#9c280d', color: 'white' },
                        }}
                    >
                        Reject All
                    </Button>
                    <Button
                        variant={'outlined'}
                        className={'cookieBannerBtn'}
                        onClick={handleCloseModal}
                        sx={{
                            margin: '0 1rem',
                            backgroundColor: '#141414',
                            color: 'white',
                            borderWidth: '0',
                            '&:hover': { backgroundColor: '#14141499', color: 'white', borderWidth: '0' },
                        }}
                    >
                        Save Preferences
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};
