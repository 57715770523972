export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || process.env.REACT_APP_GTM_ID || '';
export const COOKIE_URL = process.env.NEXT_PUBLIC_COOKIE_URL || process.env.REACT_APP_COOKIE_URL || '';

export const RestrictedCountries = [
    'CN',
    'RU',
    'CU',
    'SY',
    'KR',
    'IR',
    'BY',
    'UA',
    'BI',
    'CD',
    'IQ',
    'LB',
    'LR',
    'ML',
    'NI',
    'SO',
    'SD',
    'VE',
    'YE',
    'ZW',
    'SS',
    'CG',
    'CI',
];

export const emailRegex = /\S+@\S+\.\S+/;

export const PolygonMainnet = 137;
export const PolygonTestnet = 80001;
export const ArbitrumMainnet = 42161;
export const ArbitrumTestnet = 421613;

export const IS_BROWSER = typeof window !== 'undefined';

// Values for default consent configuration of GA
export const defConsent = {
    ad_storage: 'denied',
    analytics_storage: 'granted',
};


export const MinPwdLength = 8
export const MaxPwdLength = 16


export const TabOptions = {
    Signup: "signup",
    Login: "login",
    ForgotPassword: "forgot-password"
}
