import { isProdHost } from "../utils/isProduction";
import { ethers } from "ethers";
import React from "react";
import { downloadMetamask } from "../utils/index";
import {
  PolygonMainnet,
  PolygonTestnet,
  ArbitrumMainnet,
  ArbitrumTestnet,
} from "../../utils/constants";
import { verifyNetwork } from "../utils/isProduction";
import {
  addPolygonMainnet,
  addArbitrumMainnet,
  addArbitrumTestnet,
  addPolygonTestnet,
} from "../../utils/helper";

export default function useSwitchNetwork() {
  async function verifyPolygonMainnet(connect) {
    let chainId = null;
    const selectedChainId =
      window.ethereum && window.ethereum.networkVersion
        ? Number(window.ethereum.networkVersion)
        : 0;
    if (isProdHost()) {
      if (selectedChainId && selectedChainId === ArbitrumTestnet)
        chainId = ArbitrumMainnet;
      else chainId = PolygonMainnet;
    } else {
      if (selectedChainId && selectedChainId === ArbitrumMainnet)
        chainId = ArbitrumTestnet;
      else chainId = PolygonTestnet;
    }
    if (window.ethereum) {
      if (!verifyNetwork(Number(window.ethereum.networkVersion))) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: ethers.utils.hexValue(chainId) }],
          });
          await connect();
        } catch (err) {
          const errorCode = err?.data?.originalError?.code ?? err?.code;

          if (errorCode === 4902) {
            if (isProdHost()) {
              if (chainId === ArbitrumMainnet) await addArbitrumMainnet();
              else await addPolygonMainnet();
              await connect();
            } else {
              if (chainId === ArbitrumTestnet) await addArbitrumTestnet();
              else await addPolygonTestnet();
              await connect();
            }
          }
        }
      } else {
        await connect();
      }
    } else {
      downloadMetamask();
    }
  }

  return { verifyPolygonMainnet };
}
