import React from 'react';
import { Helmet } from 'react-helmet-async';
import { GTM_ID, defConsent } from '../../utils/Constants/constants';

export default function GaInitScripts() {
    return (
        <Helmet>
            <script id="gtag-init" strategy="afterInteractive">
                {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
                'analytics_storage': '${defConsent.analytics_storage}',
                'ad_storage': '${defConsent.ad_storage}'
            });
            gtag('js', new Date());`}
            </script>
            <script id="gtag-base" strategy="afterInteractive">
                {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `}
            </script>
        </Helmet>
    );
}
