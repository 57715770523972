import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Nvg8GreenButton } from "../../svgComponents";
import {
    Box,
    Button,
    Dialog,
    Typography,
    TextField,
} from "@mui/material";
import isEmail from "is-email";


const useStyles = makeStyles({
    modal: {
        backgroundColor: "#ffffff",
        color: "#141414",
        padding: "0  1.5rem",
        border: "1px solid rgba(127, 127, 127, 0.2)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    btn: {
        backgroundColor: "#fffff",
        color: "#7F7F7F",
        border: "1px solid rgba(127, 127, 127, 0.2)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "1rem",
        padding: "0.6rem",
        borderRadius: "5px",
        textTransform: "capitalize",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#141414",
        },
    },
    logo: {
        width: "2rem",
        height: "2rem",
        marginRight: "0.5rem",
    },
});
const selectStyles = {
    backgroundColor: "white",
    width: "100%",
    marginTop: "10px",
};

const LoginDialog = ({
    open,
    handleClose,
    loading,
    loginWithEmail
}) => {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");


    const handleChange = (event) => {
        setError("");
        setEmail(event.target.value);
    };

    const login = () => {
        console.log("login")
        if (email) {
            if (!isEmail(email)) {
                setError("Enter Valid Email");
                return;
            }
            console.log("with email")
            loginWithEmail(email);
        } else setError("Email is Required");
    };

    return (
        <React.Fragment>
            <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"xs"}>
                <Box className={classes.modal}>
                    <Nvg8GreenButton
                        style={{
                            width: "5rem",
                            height: "5rem",
                        }}
                    />
                    <Typography variant="h5" component="div" className={"font-medium"}>
                        Navigate Maps
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            mt: "20px",
                        }}
                        alignItems={"center"}
                    >
                        <Box sx={{ width: "100%" }} mb={2}>
                            <Box display="flex" flexDirection="column">
                                <form>
                                    <TextField
                                        sx={selectStyles}
                                        id="country-select"
                                        value={email}
                                        onChange={handleChange}
                                        fullWidth
                                        placeholder="Enter Email"
                                        error={Boolean(error)}
                                        helperText={error}
                                        FormHelperTextProps={{
                                            sx: { margin: "0px 3px" },
                                        }}
                                    />

                                    <Box
                                        onClick={login}
                                        id="nvg8-maps-login"
                                        sx={{
                                            backgroundColor: "#141414",
                                            color: "#ffffff",
                                            fontSize: "0.8rem",
                                            width: "95%",
                                            display: "flex",
                                            marginTop: "15px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "0.6rem",
                                            borderRadius: "5px",
                                            textTransform: "capitalize",
                                            pointerEvents: loading && "none",
                                            cursor: "pointer",
                                            "&:hover": {
                                                boxShadow: "0 0 5px #999999",
                                            },
                                        }}
                                    >
                                        Login With Email &nbsp;
                                        {loading && <span className="loader"></span>}
                                    </Box>
                                </form>
                                <Button
                                    style={{
                                        color: "#7F7F7F",
                                        margin: "15px auto",
                                        width: "100%",
                                        textTransform: "capitalize",
                                    }}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </React.Fragment>
    );
};

export default LoginDialog;
