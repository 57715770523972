import React from 'react'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

export const SearchBar = (mapboxgl) => {
    return new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        marker: false,
        render: function (item) {
            return `<div class='geocoder-dropdown-item'>
            <span class='geocoder-dropdown-text'>
            ${item.place_name}
            </span>
            </div>`;
        },
        mapboxgl: mapboxgl
    });
}
