import { ArbitrumMainnet, ArbitrumTestnet, PolygonMainnet, PolygonTestnet } from "../../utils/constants";
export const isProdHost = () => typeof window !== "undefined" && (window?.location?.hostname === 'maps.nvg8.io' || window?.location?.hostname === 'qa.maps.nvg8.io');
export const isProdOnly = () => typeof window !== "undefined" && (window?.location?.hostname === 'maps.nvg8.io');

export const verifyNetwork = (chainId) => {
    if (isProdHost()) {
        return chainId === PolygonMainnet || chainId === ArbitrumMainnet;
    } else {
        return chainId === PolygonTestnet || chainId === ArbitrumTestnet;
    }
};