import { ExpandMore } from "@material-ui/icons";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { Button, MenuItem } from "@material-ui/core";
import { Typography } from "@mui/material";
export const FeedbackButton = ({ classes, handleClickOpenFeedback, handleClickOpenReport, handleClick, selectedTile, anchorEl, toggleMapillary, mapillaryFeature, showStreet }) => {
    return (<div className={classes.feedbackButtonContainer}>
        {Boolean(anchorEl) && (
            <div className={classes.feedbackOptionsContainer}>
                <MenuItem
                    className={classes.feedbackOption}
                    onClick={handleClickOpenFeedback}
                    style={{ borderBottom: "1px solid #7F7F7F" }}
                >
                    <ListItemIcon>
                        <ChatBubbleOutlineIcon
                            fontSize="small"
                            style={{ color: "white" }}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Send Feedback" />
                </MenuItem>
                <MenuItem
                    className={classes.feedbackOption}
                    onClick={handleClickOpenReport}
                    style={{
                        opacity: (!showStreet && selectedTile) || (showStreet && mapillaryFeature) ? 1 : 0.5,
                        pointerEvents: (!showStreet && selectedTile) || (showStreet && mapillaryFeature) ? "inherit" : "none",
                    }}
                >
                    <ListItemIcon>
                        <ReportProblemOutlinedIcon
                            fontSize="small"
                            style={{ color: "white" }}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography>Report issue</Typography>
                    </ListItemText>
                </MenuItem>
            </div>
        )}
        {!toggleMapillary && <Button
            variant="contained"
            className={classes.feedbackButton}
            onClick={handleClick}
            endIcon={<ExpandMore style={{ color: "white" }} />}
            id="feedbackButton"
        >
            Send Feedback{" "}
        </Button>
        }
    </div>)
}

export default FeedbackButton