import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import { Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import Radio from '@mui/material/Radio';
import { getQueryParam } from "../utils/helper"

export const checkMapillaryCode = (code) => code === "dfcf93e8-b292-4c4d-866f-5c248a1504c4"

export const useStyles = makeStyles(() => ({
  checkbox: {
    color: 'white',
    marginRight: '5px',
    padding: '0px !important',
    margin: '10px 5px !important',
    '&.Mui-checked': { color: '#C8DC3C !important' },
    '&.MuiRadio-root ': { color: 'white' },
    '&.Mui-disabled ': { color: 'rgba(255,255,255,0.5)' },
    '&.MuiCheckbox-indeterminate': { color: '#C8DC3C' },
  },
}));

export function LeftMenu({
  locateMe,
  showDetails,
  handleStreetView,
  showStreet,
  open,
  setOpen,
}) {
  const classes = useStyles();
  const handleClick = () => {
    setOpen(!open);
  };
  const mapillary_guid = getQueryParam("mapillary_guid")
  const guidCheck = checkMapillaryCode(mapillary_guid)


  return (
    <>
      {showDetails ? (
        <List
          style={{
            backgroundColor: '#242424',
            borderRadius: '0px 8px 8px 0px',
            padding: 0,
          }}
        >
          <ListItem style={{ padding: 10, cursor: 'pointer' }}>
            <Box
              id="layerButtonId"
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                cursor: 'pointer',
                width: '100%',
              }}
            >
              <img
                alt=""
                src="/images/layersIcon.webp"
                width={16}
                height={16}
              />
            </Box>
          </ListItem>{' '}
          {/* <Divider style={{ height: "1px", backgroundColor: "#7F7F7F" }} />
          <ListItem
            style={{
              backgroundColor: showSlider ? "#C8DC3C" : "inherit",
              padding: 10,
              cursor: "pointer",
            }}
          >
            <Box
              id="compareBtn"
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                cursor: "pointer",
                width: "100%",
              }}
              onClick={toggleSlider}
            >
              <img
                alt=""
                src="/images/compare.webp"
                style={{ width: "1rem", filter: showSlider && "invert(100%)" }}
              />
            </Box>
          </ListItem> */}
          <Divider style={{ height: '1px', backgroundColor: '#7F7F7F' }} />
          <ListItem style={{ padding: 10, cursor: 'pointer' }}>
            <Box
              id="locateBtn"
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                cursor: 'pointer',
                width: '100%',
              }}
              onClick={locateMe}
            >
              <img alt="" src="/images/locate.webp" width={16} height={16} />
            </Box>
          </ListItem>
        </List>
      ) : (
        <List
          style={{
            minWidth: '260px',
            backgroundColor: '#242424',
            borderRadius: '0px 8px 8px 0px',
            padding: 0,
          }}
        >
          <ListItem
            style={{
              cursor: 'pointer',
            }}
          >
            <Box
              id="layerButtonId"
              style={{
                width: '100%',
                paddingLeft: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={handleClick}
            >
              <img
                alt=""
                src="/images/layersIcon.webp"
                width={16}
                height={16}
                style={{ marginRight: '10px' }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  style={{
                    color: 'white',
                    marginLeft: 2,
                    fontSize: '14px',
                  }}
                >
                  {'Layers'}
                </Typography>
                {open ? (
                  <ExpandLess style={{ color: 'white' }} />
                ) : (
                  <ExpandMore style={{ color: 'white' }} />
                )}
              </div>
            </Box>
          </ListItem>{' '}
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/* <Box
              style={{
                paddingLeft: 20,
                paddingRight: 7,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox size="small" className={classes.checkbox} disabled />
              <Typography
                style={{
                  color: "rgba(255,255,255,0.5)",
                  fontSize: "14px",
                }}
              >
                Data Density (coming soon)
              </Typography>
            </Box> */}
            <Box
              style={{
                paddingLeft: 20,
                paddingRight: 7,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => handleStreetView('aerial')}
            >
              {' '}
              <Radio
                size="small"
                className={classes.checkbox}
                onChange={() => handleStreetView('aerial')}
                checked={!showStreet}
              />
              <Typography
                style={{
                  color: 'white',
                  fontSize: '14px',
                }}
              >
                Aerial Footage
              </Typography>
            </Box>
            <Box
              style={{
                paddingLeft: 20,
                paddingRight: 7,
                display: 'flex',
                alignItems: 'center',
                cursor: guidCheck ? 'pointer' : "default",
                pointerEvents: guidCheck ? 'all' : "none",
                opacity: guidCheck ? 1 : 0.5

              }}
              onClick={() => guidCheck && handleStreetView('dashcam')}

            >
              {' '}
              <Radio
                size="small"
                className={classes.checkbox}
                onChange={() => guidCheck && handleStreetView('dashcam')}
                checked={showStreet}
              />
              <Typography
                style={{
                  color: 'white',
                  fontSize: '14px',
                }}
              >
                {guidCheck ? 'Dashcam' : 'Dashcam (Coming soon)'}
              </Typography>
            </Box>

            {/* </List> */}
          </Collapse>
          {/* <Divider style={{ height: "1px", backgroundColor: "#7F7F7F" }} />
          <ListItem
            style={{
              backgroundColor: showSlider ? "#C8DC3C" : "inherit",
              cursor: "pointer",
            }}
          >
            <Box
              style={{
                width: "100%",
                cursor: "pointer",
                paddingLeft: 2,
                paddingRight: 7,
                display: "flex",
                alignItems: "center",
              }}
              id="compareBtn"
              onClick={toggleSlider}
            >
              <img
                alt=""
                src="/images/compare.webp"
                style={{
                  width: "1rem",
                  marginRight: "10px",
                  filter: showSlider && "invert(100%)",
                }}
              />

              <Typography
                style={{
                  color: showSlider ? "black" : "white",
                  marginLeft: 2,
                  fontSize: "14px",
                }}
              >
                Compare Imagery
              </Typography>
            </Box>
          </ListItem> */}
          <Divider style={{ height: '1px', backgroundColor: '#7F7F7F' }} />
          <ListItem style={{ cursor: 'pointer' }}>
            <Box
              id="locateBtn"
              style={{
                width: '100%',
                cursor: 'pointer',
                paddingLeft: 2,
                paddingRight: 7,
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={locateMe}
            >
              <img
                alt=""
                src="/images/locate.webp"
                width={16}
                height={16}
                style={{ marginRight: '10px' }}
              />

              <Typography
                style={{
                  color: 'white',
                  marginLeft: 2,
                  fontSize: '14px',
                }}
              >
                Locate me
              </Typography>
            </Box>
          </ListItem>
        </List>
      )}
    </>
  );
}
