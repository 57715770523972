import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Box, RadioGroup, FormControlLabel, FormControl, Button, Grid, Skeleton, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CategoriesIcon, CustomRadio } from '../Common';
import { backendURL, } from "../../utils/constants"
import { useAppContext } from '../../lib/context/app-context';
import { useTheme } from '@emotion/react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { getAnnotations, getCategories } from "../../Api/api"
import { formateDate, removeAnnotationsLayer } from "../../utils/helper"
import moment from 'moment';
import { addAnnotationsToMap } from '../../components/AddTilesLayerToMap'
import { AustinPolygon } from '../../utils/constants';


export function RadioButtonsGroup({ selectedCategory, setSelectedCategory, loadingCategories }) {
    const { allCategories, setSelectedCategoryObject } = useAppContext()

    const handleChangeCity = (e) => {
        setSelectedCategory(e.target.value);
    }

    return (
        loadingCategories ?
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                <Skeleton width="50%" height="20px" sx={{ m: 1 }} />
                <Skeleton width="50%" height="20px" sx={{ m: 1 }} />
                <Skeleton width="50%" height="20px" sx={{ m: 1 }} />
            </Box> :
            <FormControl sx={{ width: "100%" }}>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={selectedCategory ?? ""}
                    name="radio-buttons-group"
                    onChange={handleChangeCity}
                >
                    {
                        allCategories?.map((category, index) => <FormControlLabel onClick={() => setSelectedCategoryObject(category)} key={index} sx={{ textTransform: "capitalize", userSelect: "none", opacity: selectedCategory === category?.name ? 1 : 0.5 }} value={category?.name} control={<CustomRadio />} label={category?.alias} />)
                    }
                </RadioGroup>
            </FormControl >
    );
}

export function DataList() {
    const theme = useTheme()
    const { map, annotations, selectedAnnotation, setSelectedAnnotation, } = useAppContext()

    const handleAnnotationSelection = (annotation) => {
        var clickedFeature = annotation;
        if (clickedFeature?.geometry?.coordinates) { map.current.flyTo({ center: clickedFeature?.geometry?.coordinates, zoom: 15 }) }
        let data = {
            type: 'FeatureCollection',
            features: [...annotations],
        }
        const allFeatures = data.features
        if (allFeatures.length) {
            const prevClickedFeatureIndex = allFeatures.findIndex(item => item?.properties?.clicked === "true");
            if (prevClickedFeatureIndex > -1)
                allFeatures[prevClickedFeatureIndex] = { ...allFeatures[prevClickedFeatureIndex], properties: { ...allFeatures[prevClickedFeatureIndex].properties, clicked: "false" } }

            const clickedFeatureIndex = allFeatures.findIndex(item => item?.properties?.id === clickedFeature?.properties?.id);
            allFeatures[clickedFeatureIndex] = { ...allFeatures[clickedFeatureIndex], properties: { ...allFeatures[clickedFeatureIndex].properties, clicked: "true" } }
            setSelectedAnnotation(allFeatures[clickedFeatureIndex])
        }
        const updatedData = { ...data, features: [...allFeatures] };
        map.current.getSource('annotations-data').setData(updatedData);

    }

    const checkSelectedAnnotation = (item) => selectedAnnotation?.properties?.id === item.properties.id
    return (
        <Grid container sx={{ justifyContent: "space-between" }}>
            {annotations?.map((item, index) => {
                return <Grid item xs={6} sx={{ cursor: "pointer", justifyContent: "center", mb: 1, display: 'flex' }} key={index}>
                    <Box sx={{ width: "200px", height: "200px", position: "relative", }}>
                        <img src={item?.properties?.url} alt={item?.annotation?.annotatedId} style={{ width: "100%", height: "100%" }} />
                        <Box
                            onClick={() => handleAnnotationSelection(item)}
                            sx={{
                                cursor: 'pointer',
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                width: '200px',
                                height: '200px',
                                opacity: 0,
                                zIndex: 1,
                                '&:hover': {
                                    opacity: 1,
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    background: checkSelectedAnnotation(item) ? theme.palette.black.main : theme.palette.theme_white.light,
                                    width: '100%',
                                    height: '70px',
                                    display: 'flex',
                                }}
                            >
                                <Box sx={{ width: "100%", p: 1, textAlign: "start", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Box sx={{ display: "flex", flexDirection: 'column', }}>
                                        <Typography
                                            sx={{ fontSize: '10px', color: checkSelectedAnnotation(item) ? "white" : "black " }}
                                            variant='div'
                                        >
                                            {moment(formateDate(item.properties.captured_at)).format("DD.MM.YYYY dddd")}
                                        </Typography>
                                        <Typography
                                            sx={{ fontSize: '10px', color: checkSelectedAnnotation(item) ? "white" : "black " }}
                                            variant='div'
                                        >
                                            latitude {' '}
                                            <Typography
                                                sx={{ fontSize: '12px', fontWeight: "bold", color: checkSelectedAnnotation(item) ? "white" : "black " }}
                                                variant='span'
                                            >
                                                {item?.geometry?.coordinates && item?.geometry?.coordinates[1].toFixed(2)}{' '}
                                            </Typography>
                                            longitude {' '}

                                            <Typography
                                                sx={{ fontSize: '12px', fontWeight: "bold", color: checkSelectedAnnotation(item) ? "white" : "black " }}
                                                variant='span'
                                            >
                                                {item?.geometry?.coordinates && item?.geometry?.coordinates[0].toFixed(2)}{' '}
                                            </Typography>
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Box sx={{
                                            height: "25px", width: "25px", borderRadius: "100%", bgcolor: checkSelectedAnnotation(item) ? "#15C679" : theme.palette.theme_white.main,
                                            display: 'flex', justifyContent: 'center', alignItems: 'center',
                                        }}>
                                            {checkSelectedAnnotation(item) ? <VisibilityOff sx={{ color: "black", fontSize: "14px" }} /> : <Visibility sx={{ color: "#15C679", fontSize: "14px" }} />}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            })}
        </Grid>
    );
}

export const Categories = ({ expanded, handleChange, showMenu }) => {
    const theme = useTheme()
    const [selectedCategoryLocal, setSelectedCategoryLocal] = React.useState("")
    const { map, selectedCategoryObject, setSelectedCategory, selectedCategory, setAllCategories, setAnnotations, annotations, setSelectedAnnotation } = useAppContext()
    const [loadingCategories, setLoadingCategories] = React.useState(false);
    const [loadingAnnotations, setLoadingAnnotations] = React.useState(false)
    const austinCoords = AustinPolygon?.geometry?.coordinates[0].map(item => { return { coordinates: item } })

    const handleShowDetails = async () => {
        setLoadingAnnotations(true)
        const url = `${backendURL}api/annotations`
        const body = { labels: [selectedCategoryLocal], polygon: austinCoords }
        const result = await getAnnotations(url, body)
        if (result?.success) {
            const data = result?.data?.data
            if (data) {
                data.sort((a, b) => formateDate(a.properties.captured_at) - formateDate(b.properties.captured_at));
                setAnnotations(data)
                const geoJson = {
                    type: 'FeatureCollection',
                    features: [...data],
                }
                addAnnotationsToMap(map, geoJson, setSelectedAnnotation);
                setSelectedCategory(selectedCategoryLocal);

            }
        }

        setLoadingAnnotations(false)
    }

    const handleClearSelection = () => {
        removeAnnotationsLayer(map)
        setSelectedCategory("");
        setSelectedAnnotation(null)
    }

    const handleGetCategories = async () => {
        setLoadingCategories(true)
        const url = `${backendURL}api/annotations/categories`
        const result = await getCategories(url)
        if (result?.success) {
            setAllCategories(result?.data?.data)
        }
        setLoadingCategories(false)
    }

    React.useEffect(() => {
        handleGetCategories()
    }, [])
    return (
        showMenu ? <Box>
            {!selectedCategory ?
                <Accordion
                    expanded={expanded === 'panel2'}
                    onChange={handleChange('panel2')}
                    square={true}
                    sx={{ p: 2, boxShadow: "none", bgcolor: "white", m: 0 }}
                    TransitionProps={{ unmountOnExit: true }}
                >

                    <Box sx={{ display: "flex", alignItems: "center", }}>
                        <CategoriesIcon />
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: "#0D1F23" }} />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"

                            sx={{
                                px: 0, flexGrow: 1, pb: 0.5,
                                "&.Mui-expanded": {
                                    borderBottom: "1px solid #0D1F231A"

                                }
                            }}
                        >
                            <Typography>
                                Select Categories
                            </Typography>
                        </AccordionSummary>
                    </Box>
                    <AccordionDetails sx={{ px: 0 }}>
                        <RadioButtonsGroup setSelectedCategory={setSelectedCategoryLocal} selectedCategory={selectedCategoryLocal} loadingCategories={loadingCategories} />
                        <Box sx={{ display: "flex", justifyContent: "end" }}>
                            <Button variant="contained" color='black' onClick={handleShowDetails}>
                                Show Details {loadingAnnotations && <CircularProgress sx={{ color: "white", ml: 1 }} size={15} />}
                            </Button>
                        </Box>

                    </AccordionDetails>
                </Accordion> :
                <Accordion
                    expanded={expanded === 'panel2'}
                    onChange={handleChange('panel2')}
                    square={true}
                    sx={{ p: 2, boxShadow: "none", bgcolor: theme.palette.theme_white.light, m: 0, backdropFilter: "blur(12px)" }}
                    TransitionProps={{ unmountOnExit: true }} disableGutters
                >

                    <Box sx={{ display: "flex", alignItems: "center", }}>
                        <CategoriesIcon bgcolor={"#0769FC"} />
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: "#0D1F23" }} />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            sx={{
                                px: 0, flexGrow: 1, pb: 0.5,
                                "&.Mui-expanded": {
                                    borderBottom: "1px solid #0D1F231A"

                                }
                            }}
                        >
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography sx={{ fontWeight: "bold", textDecoration: "underline", textTransform: "capitalize" }}>
                                    {selectedCategoryObject?.alias} ({annotations?.length})
                                </Typography>
                                <Box sx={{ bgcolor: theme.palette.gray.light, py: 1, px: 2, borderRadius: "50px", cursor: "pointer" }} onClick={handleClearSelection}>
                                    <Typography sx={{ fontSize: "10px", fontWeight: "bold", }}>
                                        Reset
                                    </Typography>
                                </Box>
                            </Box>
                        </AccordionSummary>
                    </Box>
                    <AccordionDetails sx={{ px: 0 }}>
                        <DataList />
                    </AccordionDetails>
                </Accordion>
            }
        </Box> :
            <Box sx={{ mt: 4, ml: 1.5 }}>
                <CategoriesIcon />
            </Box>
    );
}
