import CloseIcon from "@material-ui/icons/Close";
import { Typography, Slider as MUISlider } from "@mui/material";


export const Slider = ({ toggleSlider, handleChangeOpacity, opacity, classes }) => {
    return (
        <div className={classes.sliderContainer}>
            <div className={classes.slider}>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                    }}
                >
                    <CloseIcon
                        className={classes.closeIcon}
                        onClick={toggleSlider}
                    />
                </div>
                <div className={classes.sliderContent}>
                    <Typography sx={{ color: "#FFFFFF", fontSize: "14px" }}>
                        Slide To Compare Imagery
                    </Typography>

                    <MUISlider
                        size="small"
                        aria-label="opacity"
                        value={opacity}
                        onChange={handleChangeOpacity}
                        color="primary"
                    />
                </div>
            </div>
        </div>
    )
}
export default Slider;