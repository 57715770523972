import React, { useState, useEffect } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { CookieControls } from "./CookieControls";

const bannerBtnStyle = {
  width: 0,
  height: 0,
  visibility: "hidden",
  padding: 0,
  margin: 0,
  position: "absolute",
};

export default function ConsentBanner() {
  // States to handle consent for ads and analytics individually
  const [ads, setAds] = useState(false);
  const [analytics, setAnalytics] = useState(false);

  // On wondow load, check for CookieConsent

  useEffect(() => {
    if (getCookieConsentValue()) {
      const cookieValue = JSON.parse(getCookieConsentValue());
      const checkGtag = () => {
        if (window.gtag && typeof window.gtag === "function") {
          window.gtag("consent", "update", {
            analytics_storage: "granted",
            ad_storage: cookieValue.ads ? "granted" : "denied",
          });
        } else {
          setTimeout(checkGtag, 100);
        }
      };
      checkGtag();
    }
  }, []);

  // Update consent on Button Click
  async function handleConsentChange(ad, analytic) {
    setAds(ad);
    setAnalytics(analytic);
    if (window.gtag) {
      window.gtag("consent", "update", {
        analytics_storage: "granted",
        ad_storage: ad ? "granted" : "denied",
      });
    }
  }

  return (
    <CookieConsent
      location="bottom"
      acceptOnScroll={false}
      overlay={false}
      enableDeclineButton
      buttonClasses={"cookie-button"}
      visible={"byCookieValue"}
      extraCookieOptions={{ domain: ".nvg8.io" }}
      buttonId={"cookiesAcceptNode"}
      cookieValue={`{"ads": ${ads}}`}
      style={{ display: "block" }}
      buttonStyle={bannerBtnStyle}
      declineButtonStyle={bannerBtnStyle}
      buttonText={""}
      expires={90}
      debug={false}
      declineButtonId={"cookiesRejectNode"}
      declineCookieValue={`{"ads": ${ads}}`}
    >
      <CookieControls handleConsentChange={handleConsentChange} />
    </CookieConsent>
  );
}
