import * as React from "react";

const SvgCoinGreen = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={0.5}
      width={19}
      height={19}
      rx={9.5}
      fill="#C8DC3C"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.85 8.67c.055 0 .099-.046.096-.101-.025-.498.042-.938.4-1.298.357-.359.808-.387 1.277-.393a.097.097 0 0 0 .096-.097v-.626a.496.496 0 0 0-.003-.045.098.098 0 0 0-.084-.088c-1.617-.211-2.765 1.137-2.555 2.565a.098.098 0 0 0 .097.084h.675Zm-4.338-.104c-.14-1.571 1.007-2.713 2.555-2.55.05.006.088.048.088.098v.663a.097.097 0 0 1-.1.097C7.92 6.84 7.347 7.4 7.375 8.556a.097.097 0 0 1-.097.1h-.669a.098.098 0 0 1-.097-.09Zm7.192-.776h.673c.049 0 .09.036.097.085a2.24 2.24 0 0 1-2.213 2.572l-.485.005h-.001c-.96.011-1.921.023-2.88-.013-.841-.03-1.552.567-1.497 1.487.003.057 0 .115-.006.184l-.001.022a.098.098 0 0 1-.097.09h-.671a.098.098 0 0 1-.097-.083c-.205-1.339.814-2.573 2.15-2.585.783-.008 1.567-.006 2.351-.004l1.067.001c.97 0 1.5-.526 1.513-1.5v-.163c0-.054.044-.098.097-.098Zm-.086 3.633c0-.055.043-.099.097-.099h.667c.049 0 .09.037.097.086.185 1.385-.85 2.726-2.538 2.579a.098.098 0 0 1-.09-.097v-.69c0-.054.045-.098.1-.097.47.01.917-.033 1.272-.398.347-.356.4-.795.395-1.284Zm-5.33 1.783v.679c0 .05.038.091.088.097 1.637.177 2.744-1.153 2.548-2.555a.098.098 0 0 0-.097-.084h-.68c-.055 0-.1.046-.097.101.021.476-.039.916-.394 1.275-.357.362-.807.388-1.271.39a.097.097 0 0 0-.097.097Z"
      fill="#141414"
    />
    <rect x={1} y={0.5} width={19} height={19} rx={9.5} stroke="#C8DC3C" />
  </svg>
);

export default SvgCoinGreen;
