import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  walletAddress: "",
  jwtToken: "",
  terms_status: false,
};

const loginSlice = createSlice({
  name: "auth/login",
  initialState,
  reducers: {
    setWalletAddress(state, action) {
      state.walletAddress = action.payload;
      localStorage.setItem("walletAddress", action.payload);
    },
    setToken(state, action) {
      state.jwtToken = action.payload;
      localStorage.setItem("jwtToken", action.payload);
    },
    setTermStatus(state, action) {
      state.terms_status = Boolean(action.payload);
      localStorage.setItem("terms_status", Boolean(action.payload));
    },
    setLogout(state) {
      state.jwtToken = "";
      state.walletAddress = "";
      state.terms_status = false;
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("walletAddress");
      localStorage.removeItem("terms_status");
    },
  },
});

export const { setToken, setWalletAddress, setTermStatus, setLogout } =
  loginSlice.actions;

export default loginSlice.reducer;
