import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useAppContext } from '../../lib/context/app-context';
import { satelliteBaseMap } from '../../utils/constants';
import moment from 'moment';
import { formateDate } from '../../utils/helper';

const numberOfDivisions = 53

export const Timeline = () => {
    const theme = useTheme();
    const data = Array.from({ length: numberOfDivisions + 1 }, (_, index) => index + 1);
    const { baseMap, selectedAnnotation } = useAppContext()
    const [selectedSpan, setSelectedSpan] = useState(-1)
    const [firstDate, setFirstDate] = useState('')
    const [lastDate, setLastDate] = useState('')
    const { annotations } = useAppContext()


    function getSelectedSpan(selectedDate) {
        const totalDuration = lastDate - firstDate;
        const spanDuration = totalDuration / numberOfDivisions;
        const diffFromFirstDate = selectedDate - firstDate;
        const selectedSpan = Math.floor(diffFromFirstDate / spanDuration);
        return selectedSpan >= 0 ? selectedSpan : 0; // Adding 1 to start spans from 1
    }

    useEffect(() => {
        if (annotations) {
            const data = [...annotations]
            const firstDate = formateDate(data[0].properties.captured_at);
            const lastDate = formateDate(data[data.length - 1].properties.captured_at);
            setFirstDate(firstDate);
            setLastDate(lastDate);
        }

    }, [annotations])

    useEffect(() => {
        if (selectedAnnotation) {
            const selectedSpan = getSelectedSpan(formateDate(selectedAnnotation?.properties?.captured_at));
            setSelectedSpan(selectedSpan)
        }
    }, [selectedAnnotation])

    return (
        <Box
            sx={{
                position: 'absolute',
                left: '500px',
                bottom: '20px',
                width: '600px',
                minHeight: '50px',
                zIndex: 10,
                backgroundColor: baseMap === satelliteBaseMap ? theme.palette.theme_white.light : "#0D1F2340",
                borderRadius: "50px",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Box sx={{ width: "100%", position: "relative", height: "inherit", px: 3, display: 'flex', justifyContent: "center", }}>
                <Box sx={{ display: 'flex', alignItems: 'center', position: "absolute", top: 15, left: 12, }}>
                    <Box sx={{ bgcolor: "#0D1F23", borderRadius: "50px", display: 'flex', alignItems: 'center', p: 0.5 }}>
                        <img src="/clock.webp" alt="clock" style={{ width: "15px", }} />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', position: "absolute", top: 18, left: 50 }}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
                        {moment(firstDate).format("MMM YYYY")}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', position: "absolute", top: 18, right: 40 }}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
                        {moment(lastDate).format("MMM YYYY")}
                    </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end", height: "100%",
                    width: "80%",
                }}>
                    {data.map((item, index) => {
                        return index === selectedSpan ? <Box
                            key={index}
                            sx={{
                                width: "2px",
                                height: "100%",
                                display: "flex",
                                alignItems: "flex-end",
                                position: "relative"
                            }} >
                            <img src="/marker.svg" style={{ width: "20px", height: "20px", position: "absolute", top: 12, left: -9.03 }}></img>
                            <Box sx={{
                                width: "2px",
                                height: "25px",
                                bgcolor: "#0D1F23",
                                position: "relative"
                            }} />
                        </Box> :
                            <Box
                                key={index}
                                sx={{
                                    width: "2px",
                                    height: (index + 1) % 5 === 0 ? "25px" : "10px",
                                    // height: (index + 1) === 54 ? "25px" : "10px",
                                    borderLeft: "2px solid #0D1F2350",
                                    position: "relative"
                                }} >
                            </Box>
                    })}
                </Box>
            </Box>
        </Box>
    );
};
