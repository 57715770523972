import axios from "axios";
import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  BellIcon,
  Nvg8Logo,
  RightArrow,
} from "../../svgComponents";
import {
  mapsURL,
} from "../../utils/constants";
import Layout from "../../components/Layout";
import { useHistory } from "react-router-dom";
import { ethers } from "ethers";
import { CustomSnackbar } from "../../components/CustomSnackbar";
import { baseURL, INTEGRATION_TYPE } from "../../utils/constants";
import {
  setWalletAddress,
  setTermStatus,
  setToken,
} from "../../redux/reducers/auth";
import { useDispatch } from "react-redux";
import { uploadURL } from "../../utils/constants";
import LoginDialog from "./Dialog";
import { verifyNetwork } from "../../lib/utils/isProduction";
import useSwitchNetwork from "../../lib/hooks/useSwitchNetwork";

const GetStartedStyle = {
  backgroundColor: "#C8DC3C",
  color: "#141414",
  fontSize: "1rem",
  fontWeight: 500,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0.6rem 1rem",
  borderRadius: "5px",
  textTransform: "capitalize",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0 0 5px #999999",
  },
  "@media (max-width: 600px)": {
    mt: 2,
    ml: 0,
    padding: "0.8rem 2rem",
  },
};

const LearnMoreStyle = {
  backgroundColor: "#FFFFFFCC",
  color: "#141414",
  fontSize: "1rem",
  fontWeight: 500,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0.6rem 1rem",
  borderRadius: "5px",
  textTransform: "capitalize",
  cursor: "pointer",

  ml: "15px",
  "&:hover": {
    boxShadow: "0 0 5px #999999",
  },
  "@media (max-width: 600px)": {
    mt: 2,
    ml: 0,
  },
};

// const TwitterIconStyle = {
//   backgroundColor: "#FFFFFFCC",
//   color: "#141414",
//   borderRadius: "100px",
//   padding: "0.6rem",
//   display: "flex",
//   marginLeft: "10px",
//   justifyContent: "center",
//   alignItems: "center",
//   cursor: "pointer",
//   "&:hover": {
//     boxShadow: "0 0 5px #999999",
//   },

//   "@media (max-width: 600px)": {
//     mt: 2,
//   },
// };

const ButtonContainerStyle = {
  flexDirection: "row",
  alignItems: "center",
  "@media (max-width: 600px)": {
    flexDirection: "column-reverse",
    alignItems: "start",
  },
};

const InnerContainerStyle = {
  color: "#F8F8F8",

  width: "70%",
  margin: "auto",
  marginTop: "3%",
  padding: "1.5rem",
  zIndex: 2,
  "@media (max-width: 600px)": {
    width: "85%",
    padding: "1.5rem 0.5rem",
  },
};

const BannerContainerStyle = {
  marginTop: "10%",
  borderRadius: "5px",
  width: "fit-content",
  paddingRight: "10px",
  "@media (max-width: 600px)": {
    ml: 0,
  },
};

const Login = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  // const matches = useMediaQuery("(min-width:600px)");
  const history = useHistory();
  // const isProdHost = () => false;

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const { verifyPolygonMainnet } = useSwitchNetwork();

  const containerStyles = {
    flexGrow: 1,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage: "url('/images/LandingImage.webp')",
  };

  useEffect(() => {
    window.ethereum?.on("chainChanged", chainUpdateCallback);

    return () => {
      window.ethereum?.removeListener("chainChanged", chainUpdateCallback);
    };
  }, [open]);

  const connect = async () => {
    if (window.ethereum) {
      const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
      const { chainId } = await ethProvider.getNetwork();
      try {
        if (verifyNetwork(chainId)) {
          setLoading(true);
          await ethProvider
            .send("eth_requestAccounts", [])
            .then(async (res) => {
              const url = `${baseURL}users/login`;
              const data = { wallet_address: res[0], integration_type: INTEGRATION_TYPE, chainId };
              await axios
                .post(url, data)
                .then((response) => {
                  if (response?.data?.statusCode === 200 || response?.data?.statusCode === 201) {
                    dispatch(setWalletAddress(res[0]));
                    localStorage.setItem("Role", response?.data?.data?.role);
                    dispatch(setToken(response?.data?.data?.auth_token));
                    dispatch(setTermStatus(response?.data?.data?.terms_status));
                    history.push("/");
                  } else if (response?.data?.statusCode === 403) {
                    history.push("/request-invite");
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setLoading(false);
                  showErrorToast(
                    error?.message ??
                    "Sorry, An error occurred while connecting."
                  );
                });
            });
        } else {
          setLoading(false);
          showErrorToast(
            "Please connect your wallet to the Polygon Network to continue."
          );
        }
      } catch (ex) {
        setLoading(false);
        showErrorToast("Sorry, An error occurred while connecting.");
      }
    } else {
      const isMobile = detectDevice();
      if (isMobile) {
        window.open(
          `https://metamask.app.link/dapp/${window?.location?.href}`,
          "_self"
        );
      } else {
        window.open("https://metamask.io", "_blank");
      }
    }
  };

  async function chainUpdateCallback() {
    const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
    const { chainId } = await ethProvider.getNetwork();
    if (verifyNetwork(Number(chainId)) && window.location.pathname === '/login') {
      await verifyPolygonMainnet(connect);
    }
  }

  const detectDevice = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];
    return toMatch.some((toMatchItem) => {
      return navigator?.userAgent?.match(toMatchItem);
    });
  };


  const loginThroughWallet = async (wallet_address) => {
    try {
      setLoading(true);

      const url = `${baseURL}users/login`;
      const data = { wallet_address: wallet_address, integration_type: INTEGRATION_TYPE };
      await axios
        .post(url, data)
        .then((response) => {

          if (response?.data?.statusCode === 200 || response?.data?.statusCode === 201) {
            dispatch(setWalletAddress(wallet_address));
            localStorage.setItem("Role", response?.data?.data?.role);
            dispatch(setToken(response?.data?.data?.auth_token));
            dispatch(setTermStatus(response?.data?.data?.terms_status));
            history.push("/");
          } else if (response?.data?.statusCode === 403) {
            history.push("/request-invite");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          showErrorToast(
            error?.message ?? "Sorry, An error occurred while connecting."
          );
        });
    } catch (ex) {
      setLoading(false);
      showErrorToast("Sorry, An error occurred while connecting.");
    }
  };

  const showErrorToast = (error) => {
    setErrorMessage(error);
    setOpenToast(true);
  };

  const loginWithEmail = async (email) => {
    try {
      setLoading(true);
      const url = `${baseURL}users/login`;
      const data = { email_address: email, integration_type: INTEGRATION_TYPE, };
      await axios
        .post(url, data)
        .then((res) => {
          if (res?.data?.statusCode === 200) {
            dispatch(setWalletAddress(res[0]));
            dispatch(setToken(res?.data?.data?.auth_token));
            dispatch(setTermStatus(res?.data?.data?.terms_status));
            localStorage.setItem("Role", res?.data?.data?.role);
            history.push("/");
          }
          else if (res?.data?.statusCode === 403) {
            showErrorToast(
              res?.data?.message ?? "Not authenticated"
            );
          }
          else if (res?.data?.statusCode === 400) {
            showErrorToast(
              res?.data?.message
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          showErrorToast(
            error?.message ?? "Sorry, An error occurred while connecting."
          );
        });
    } catch (ex) {
      setLoading(false);
      console.log({ ex })

      showErrorToast("Sorry, An error occurred while connecting.");
    }
  };



  return (
    <Layout darkBg={false}>
      <Box sx={{ height: "100%", display: "flex" }}>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
          }}
        >
          <Grid container sx={containerStyles}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                zIndex: 2,
                p: "0 3rem",
                "@media (max-width: 600px)": {
                  p: "0 2rem",
                },
              }}
            >
              <Nvg8Logo style={{ width: "15rem", height: "5rem" }} />
              {/* <Box
                onClick={handleOpenModal}
                sx={{
                  backgroundColor: "#FFFFFF",
                  color: "#141414",
                  fontSize: "1rem",
                  display: "flex",
                  marginLeft: "15px",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0.6rem 1.3rem",
                  borderRadius: "5px",
                  textTransform: "capitalize",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "0 0 10px #999999",
                  },
                }}
              >
                <WalletIcon
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                  }}
                />
                <Typography
                  className="font-medium"
                  sx={{
                    marginLeft: "0.5rem",
                    display: matches ? "inline" : "none",
                  }}
                >
                  Connect Wallet
                </Typography>
              </Box> */}
            </Box>
            <Grid item xs={12} sm={12} md={8} lg={6} sx={{ zIndex: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box sx={InnerContainerStyle}>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: "400" }}
                    mb={2}
                  >
                    Navigate Maps{" "}
                  </Typography>
                  <Typography
                    variant="h3"
                    component="div"
                    className={"font-medium"}
                    sx={{ fontWeight: "700", lineHeight: "78.4px" }}
                  >
                    A Map Application
                  </Typography>
                  <Typography
                    variant="h3"
                    component="div"
                    className={"font-medium"}
                    sx={{ fontWeight: "700" }}
                  >
                    Powered By You
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    mt={2}
                    mb={3}
                    component="div"
                    color={"#FFFFFF"}
                  >
                    Navigate Maps is a new type of map application that
                    crowdsources high quality imagery from its community to
                    create an immersive, constantly-refreshing view of the
                    world.
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    mt={2}
                    mb={3}
                    component="div"
                    color={"#FFFFFF"}
                  >
                    Try Navigate Maps Alpha!
                  </Typography>

                  <Box display={"flex"} sx={ButtonContainerStyle}>
                    <Box onClick={handleOpenModal} sx={GetStartedStyle}>
                      <span className="font-medium">Get Started</span>
                      <RightArrow
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginLeft: "0.5rem",
                        }}
                      />
                    </Box>

                    <Box display={"flex"} sx={{ alignItems: "center" }}>
                      <a
                        href={mapsURL}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          fontSize: "inherit",
                          color: "inherit",
                          textDecoration: "inherit",
                        }}
                      >
                        <Box sx={LearnMoreStyle}>
                          <span className="font-medium"> Learn More</span>
                        </Box>
                      </a>
                    </Box>
                  </Box>

                  <Box sx={BannerContainerStyle} display={"flex"}>
                    <Box
                      sx={{
                        backgroundColor: "#C8DC3C",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0.6rem",
                        borderRadius: "5px",
                        cursor: "pointer",
                        height: "15px",
                        "&:hover": {
                          boxShadow: "0 0 5px #999999",
                        },
                      }}
                    >
                      <BellIcon
                        style={{
                          width: "1rem",
                          height: "1rem",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                        paddingRight: "10px",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          marginLeft: "10px",
                          color: "#F8F8F8",
                          alignItems: "center",
                          // fontSize: "14px",
                        }}
                      >
                        Navigate Maps Alpha is currently available to platform contributors and alpha testers. Contribute imagery here
                        <a
                          href={uploadURL}
                          target="_blank"
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          <Typography
                            variant="subtitle1"
                            component="span"
                            sx={{
                              ml: 1,
                              color: "#C8DC3C",
                              alignItems: "center",
                              // fontSize: "14px",
                              textDecoration: "underline",
                              cursor: "pointer",
                              textUnderlineOffset: 3,
                            }}
                          >
                            Data Vault
                            {/* data.nvg8.io */}
                          </Typography>
                        </a>
                        &nbsp;to gain access! <br /> Public Launch Coming Soon
                      </Typography>

                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CustomSnackbar
        open={openToast}
        handleClose={() => setOpenToast(false)}
        message={errorMessage}
        severity="error"
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      />

      <LoginDialog
        open={open}
        handleClose={handleCloseModal}
        loading={loading}
        loginThroughWallet={loginThroughWallet}
        loginWithEmail={loginWithEmail}
      />
    </Layout>
  );
};

export default Login;
