import * as React from "react";

const SvgUploadIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4.16667C4.54357 4.16667 4.16667 4.54357 4.16667 5C4.16667 5.22101 4.25446 5.43298 4.41074 5.58926C4.56702 5.74554 4.77899 5.83333 5 5.83333H14.1667V4.16667H5ZM15.8333 5.83333V3.33333C15.8333 2.8731 15.4602 2.5 15 2.5H5C3.6231 2.5 2.5 3.6231 2.5 5C2.5 5.66304 2.76339 6.29893 3.23223 6.76777C3.70107 7.23661 4.33696 7.5 5 7.5H15.8333V10C15.8333 10.4602 16.2064 10.8333 16.6667 10.8333C17.1269 10.8333 17.5 10.4602 17.5 10V6.66667C17.5 6.20643 17.1269 5.83333 16.6667 5.83333H15.8333Z"
      fill="#141414"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33333 4.16666C3.79357 4.16666 4.16667 4.53975 4.16667 4.99999V15C4.16667 15.4564 4.54357 15.8333 5 15.8333H15.8333V13.3333C15.8333 12.8731 16.2064 12.5 16.6667 12.5C17.1269 12.5 17.5 12.8731 17.5 13.3333V16.6667C17.5 17.1269 17.1269 17.5 16.6667 17.5H5C3.6231 17.5 2.5 16.3769 2.5 15V4.99999C2.5 4.53975 2.8731 4.16666 3.33333 4.16666Z"
      fill="#141414"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2322 9.89889C13.7011 9.43005 14.337 9.16666 15 9.16666H18.3333C18.7936 9.16666 19.1667 9.53975 19.1667 9.99999V13.3333C19.1667 13.7936 18.7936 14.1667 18.3333 14.1667H15C13.6231 14.1667 12.5 13.0436 12.5 11.6667C12.5 11.0036 12.7634 10.3677 13.2322 9.89889ZM15 10.8333C14.779 10.8333 14.567 10.9211 14.4107 11.0774C14.2545 11.2337 14.1667 11.4456 14.1667 11.6667C14.1667 12.1231 14.5436 12.5 15 12.5H17.5V10.8333H15Z"
      fill="#141414"
    />
  </svg>
);

export default SvgUploadIcon;
