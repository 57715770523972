import * as React from "react";

const SvgCoinGreen = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.05033 3.05025C8.36309 1.7375 10.1436 1 12.0001 1C13.8566 1 15.6371 1.7375 16.9498 3.05025C18.2626 4.36301 19.0001 6.14349 19.0001 8C19.0001 11.3527 19.7171 13.4346 20.378 14.6461C20.7098 15.2544 21.0329 15.6535 21.2573 15.8904C21.3698 16.0091 21.4581 16.0878 21.5114 16.1322C21.538 16.1544 21.5558 16.168 21.5635 16.1737C21.5647 16.1746 21.5657 16.1753 21.5664 16.1758C21.9249 16.4221 22.0835 16.8725 21.9572 17.2898C21.8295 17.7115 21.4407 18 21.0001 18H3.00008C2.55941 18 2.17068 17.7115 2.04299 17.2898C1.91664 16.8725 2.07528 16.4221 2.43377 16.1758C2.43447 16.1753 2.43542 16.1746 2.43663 16.1737C2.44432 16.168 2.46218 16.1544 2.4888 16.1322C2.54202 16.0878 2.6304 16.0091 2.74288 15.8904C2.9673 15.6535 3.29039 15.2544 3.62218 14.6461C4.28301 13.4346 5.00008 11.3527 5.00008 8C5.00008 6.14348 5.73758 4.36301 7.05033 3.05025ZM2.44388 16.169C2.44395 16.1689 2.44403 16.1688 2.44411 16.1688C2.44411 16.1688 2.4441 16.1688 2.4441 16.1688L2.44388 16.169ZM5.1494 16H18.8508C18.7747 15.8753 18.6983 15.7434 18.6222 15.6039C17.783 14.0654 17.0001 11.6473 17.0001 8C17.0001 6.67392 16.4733 5.40215 15.5356 4.46447C14.5979 3.52678 13.3262 3 12.0001 3C10.674 3 9.40223 3.52678 8.46454 4.46447C7.52686 5.40215 7.00008 6.67392 7.00008 8C7.00008 11.6473 6.21715 14.0654 5.37797 15.6039C5.30188 15.7434 5.22549 15.8753 5.1494 16Z"
      fill="#141414"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.76817 20.135C10.2459 19.8579 10.8578 20.0205 11.1349 20.4982C11.2228 20.6498 11.349 20.7756 11.5008 20.863C11.6526 20.9504 11.8247 20.9965 11.9999 20.9965C12.1751 20.9965 12.3472 20.9504 12.4991 20.863C12.6509 20.7756 12.777 20.6498 12.8649 20.4982C13.1421 20.0205 13.754 19.8579 14.2317 20.135C14.7094 20.4121 14.8721 21.0241 14.5949 21.5018C14.3312 21.9564 13.9527 22.3338 13.4973 22.5961C13.0419 22.8584 12.5255 22.9965 11.9999 22.9965C11.4744 22.9965 10.958 22.8584 10.5026 22.5961C10.0472 22.3338 9.66866 21.9564 9.40494 21.5018C9.12782 21.0241 9.29044 20.4121 9.76817 20.135Z"
      fill="#141414"
    />
  </svg>
);

export default SvgCoinGreen;
