import * as React from 'react';
import { Box, Slide } from '@mui/material';
import { SearchBox } from './SearchBox';
import { Cities } from "./CitiesAccordion"
import { Categories } from './Categories';
import { useTheme } from '@emotion/react';
import { useAppContext } from '../../lib/context/app-context';
export default function LeftMenuContainer({ showMenu,
    handleToggleMenu,
    suggestions,
    setSuggestions,
    searchHandler, }) {
    const { selectedCity } = useAppContext()
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Slide direction='right' in={true} >
            <Box sx={{ position: "relative" }}>
                <SearchBox
                    suggestions={suggestions}
                    setSuggestions={setSuggestions}
                    searchHandler={searchHandler}
                    showMenu={showMenu}
                />
                <Cities expanded={expanded} handleChange={handleChange} showMenu={showMenu} />
                {selectedCity && <Categories expanded={expanded} handleChange={handleChange} showMenu={showMenu} />}
                {showMenu && <Box sx={{ cursor: "pointer", position: "absolute", top: "50%", right: 5, height: "30px", width: "5px", borderRadius: "50px", bgcolor: "#B0B0B0" }} onClick={handleToggleMenu} />}
            </Box>
        </Slide>
    );
}

export const LeftMenu = ({
    suggestions,
    setSuggestions,
    searchHandler, setParentHeight, leftMenuRef
}) => {
    const theme = useTheme()
    const { mapContainer } = useAppContext()
    const [showMenu, setShowMenu] = React.useState(true);

    const handleToggleMenu = () => {
        setShowMenu((prev) => !prev);
    };
    React.useEffect(() => {
        if (showMenu) {
            setParentHeight()
        }
    }, [showMenu])

    return (
        <Box
            ref={leftMenuRef}
            sx={{
                position: 'absolute',
                left: '0px',
                top: '0px',
                width: !showMenu ? "50px" : '450px',
                minHeight: !showMenu ? mapContainer.current.style.height : 'auto',
                zIndex: 10,
                backgroundColor: theme.palette.theme_white.light,
                // maxHeight: mapContainer?.current?.offsetHeight,
                // maxHeight: "94.5vh",
                overflow: "auto",
            }}
        >

            <Box sx={{ cursor: "pointer", position: "absolute", top: "50%", right: 5, height: "30px", width: "5px", borderRadius: "50px", bgcolor: "#B0B0B0" }} onClick={handleToggleMenu} />

            <LeftMenuContainer
                handleToggleMenu={handleToggleMenu}
                showMenu={showMenu}
                suggestions={suggestions}
                setSuggestions={setSuggestions}
                searchHandler={searchHandler}
            />

        </Box>
    );
};
