import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Box, Radio, RadioGroup, FormControlLabel, FormControl, Button, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NavigateIcon, CustomRadio } from '../Common';
import { AllCities } from "../../utils/constants"
import { useAppContext } from '../../lib/context/app-context';
import { useTheme } from '@emotion/react';
import { formatNumber, removeAnnotationsLayer } from '../../utils/helper';

export function RadioButtonsGroup({ selectedCity, setSelectedCity }) {
    const handleChangeCity = (e) => {
        setSelectedCity(e.target.value);
    }
    return (
        <FormControl sx={{ width: "100%" }}>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedCity ?? "austin"}
                name="radio-buttons-group"
                onChange={handleChangeCity}

            >

                {AllCities && Object.keys(AllCities).map(
                    (city, index) => <FormControlLabel key={index} sx={{ userSelect: "none" }} value={AllCities[city]?.value} control={<CustomRadio />} label={AllCities[city]?.label} disabled={index === 1 || index === 2} />
                )}
            </RadioGroup>
        </FormControl >
    );
}

const AerialViewBox = () => {
    const { showRasterLayer, setShowRasterLayer, stats, } = useAppContext();

    const handleLayerToggle = () => {
        setShowRasterLayer(val => !val)
    }
    return <ViewBox type="drone" text="Drone View" imgUrl="/drone.webp" color="#0769FC" textColor="white" count={formatNumber(stats?.aerial?.totalImages ?? 0)} border={showRasterLayer ? "#0769FC" : "#0D1F231A"} onClick={handleLayerToggle} />
}
const StreetViewBox = () => {
    const { showStreetLayer, setShowStreetLayer, stats } = useAppContext();

    const handleLayerToggle = () => {
        setShowStreetLayer(val => !val)
    }
    return <ViewBox type="dashcam" text=" Dashcam View " imgUrl="/dashcam.webp" color="#15C679" textColor="black" count={formatNumber(stats?.street?.totalImages ?? 0)} border={showStreetLayer ? "#15C679" : "#0D1F231A"} onClick={handleLayerToggle} />
}

const ViewBox = ({ text, imgUrl, color, textColor, count, type, border, onClick }) => {
    const theme = useTheme()
    const { loadingStats } = useAppContext()
    return (
        <Grid item xs={5.8} onClick={onClick} sx={{ cursor: "pointer", bgcolor: theme.palette.theme_white.main, borderRadius: "8px", px: 1, py: 2, display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", border: `1px solid ${border}` }}>
            <Box>
                <img style={{ width: "15px", marginBottom: "5px" }} src={imgUrl} alt={type} />
            </Box>
            <Typography> {text} </Typography>
            {!loadingStats && <Box sx={{ mt: 2, py: 0.2, px: 2, borderRadius: "50px", bgcolor: color, color: textColor }}>
                <Typography sx={{ fontSize: "12px" }}> {count}</Typography>
            </Box>
            }
        </Grid>
    )
}

export const Cities = ({ expanded, handleChange, showMenu }) => {
    const theme = useTheme()
    const [selectedCityLocal, setSelectedCityLocal] = React.useState("austin")
    const {
        selectedCity,
        setSelectedCity,
        selectedCityObject,
        setSelectedCategory, setShowStreetLayer, setShowRasterLayer, map, setSelectedAnnotation
    } = useAppContext()

    const goToCity = () => {
        setSelectedCity(selectedCityLocal);
        const selectedCityObject = AllCities[selectedCityLocal];
        map.current.flyTo({
            zoom: 10,
            center: selectedCityObject.coordinates
        })
    }

    const handleClearSelection = () => {
        setSelectedCity("");
        setSelectedCategory("");
        setShowRasterLayer(true)
        setShowStreetLayer(true)
        setSelectedAnnotation(null)
        removeAnnotationsLayer(map)
    }

    return (

        showMenu ? <Box>
            {
                !selectedCity ? <Accordion
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                    square={true}
                    sx={{ p: 2, boxShadow: "none", bgcolor: "white", m: 0 }}
                    TransitionProps={{ unmountOnExit: true }}
                >

                    <Box sx={{ display: "flex", alignItems: "center", }}>
                        <NavigateIcon />
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: "#0D1F23" }} />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"

                            sx={{
                                px: 0, flexGrow: 1, pb: 0.5,
                                "&.Mui-expanded": {
                                    borderBottom: "1px solid #0D1F231A"

                                }
                            }}
                        >
                            <Typography>
                                Select City
                            </Typography>
                        </AccordionSummary>
                    </Box>
                    <AccordionDetails sx={{ px: 0 }}>
                        <RadioButtonsGroup setSelectedCity={setSelectedCityLocal} selectedCity={selectedCityLocal} />
                        <Box sx={{ display: "flex", justifyContent: "end" }}>
                            <Button variant="contained" color='black' onClick={goToCity}>
                                Go to City
                            </Button>
                        </Box>

                    </AccordionDetails>
                </Accordion> :
                    <Accordion
                        expanded={expanded === 'panel1'}
                        onChange={handleChange('panel1')}
                        sx={{ p: 2, boxShadow: "none", bgcolor: theme.palette.theme_white.light, m: 0, backdropFilter: "blur(12px)" }}
                        TransitionProps={{ unmountOnExit: true }} disableGutters
                    >
                        <Box sx={{ display: "flex", alignItems: "center", p: 0 }}>
                            <NavigateIcon bgcolor={"#0769FC"} />
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: "#0D1F23" }} />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    px: 0, pb: 0, flexGrow: 1
                                }}
                            >
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: "bold", textDecoration: "underline" }}>
                                        {selectedCityObject?.label}
                                    </Typography>
                                    <Box sx={{ bgcolor: theme.palette.gray.light, py: 1, px: 2, borderRadius: "50px", cursor: "pointer" }} onClick={handleClearSelection}>
                                        <Typography sx={{ fontSize: "10px", fontWeight: "bold", }}>
                                            Clear
                                        </Typography>
                                    </Box>
                                </Box>
                            </AccordionSummary>
                        </Box>

                        <AccordionDetails sx={{ pl: 5, py: 0, textAlign: "start" }}>
                            <Box sx={{ mt: -2 }}>
                                <Typography sx={{ color: theme.palette.gray.main, fontSize: "10px", }}>
                                    Available data is located in this area.
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", mt: 3 }}>
                                <Grid container sx={{ justifyContent: "space-between" }}>

                                    <StreetViewBox />
                                    <AerialViewBox />
                                </Grid>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
            }
        </Box > : <Box sx={{ mt: 4, ml: 1.5 }}><NavigateIcon /></Box>
    );
}

