import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import { getCookieConsentValue, resetCookieConsentValue } from 'react-cookie-consent';
import { Box, Button, Modal, Typography } from '@mui/material';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { COOKIE_URL } from '../../utils/Constants/constants';
import { CookieSettings } from './CookieSettings';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '5px',
    boxShadow: 24,
    maxWidth: '80vw',
    maxHeight: '65vh',
    overflowY: 'auto',
    p: 3,
};

export const CookieControls = ({ handleConsentChange }) => {
    const [checked, setChecked] = useState(() => !!getCookieConsentValue());
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    async function handleCookieAccept() {
        setChecked(false);
        await handleConsentChange(true, true);
        let node = document.getElementById('cookiesAcceptNode');
        if (node) node.click();
    }

    async function handleCookieReject() {
        resetCookieConsentValue('CookieConsent');
        await handleConsentChange(false, false);
        let node = document.getElementById('cookiesRejectNode');
        if (node) node.click();
        if (checked) setChecked(false);
        setOpen(false);
    }

    return (
        <>
            <Box flex={1} flexDirection={'column'}>
                {/* <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 600 }}>
                            Your Cookie Settings
                        </Typography>
                        <Typography variant="body2" component="div" color={'#7F7F7F'} sx={{ marginTop: 1 }}>
                            We and third parties might set cookies on your browser when you visit our website. These cookies collect
                            information about how you use the website. You may choose to enable certain cookies using the options below. If
                            you use other browsers or devices, you may have to repeat your selections on each browser or device you use. For
                            more information on how we use cookies, please review our{' '}
                            <a
                                href={COOKIE_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'underline', color: '#2e3131' }}
                            >
                                Cookies Policy
                            </a>
                        </Typography>

                        <Box flex={1} width={'100%'} flexDirection={'column'} mt={2}>
                            <Box flex={1} display={'flex'} width={'100%'} justifyContent={'space-between'}>
                                <Typography id="modal-modal-title" variant="subtitle2" component="p">
                                    Essential Cookies{' '}
                                    <Typography id="modal-modal-title" variant="body2" component="p" color={'#7F7F7F'}>
                                        Essential Cookies are necessary to allow the website to function properly. You can set your browser
                                        to block Essential Cookies, but you may not be able to use all of the pages and features of our
                                        website if you do.
                                    </Typography>
                                </Typography>
                            </Box>

                            <Box flex={1} mt={2} display={'flex'} flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
                                <Typography id="modal-modal-title" variant="subtitle2" component="h2">
                                    Analytics Cookies
                                    <Typography id="modal-modal-title" variant="body2" component="p" color={'#7F7F7F'}>
                                        Analytics Cookies inform us how visitors access and navigate our website. We use Analytics Cookies
                                        so that we can improve the layout, accessibility, and content of the website. Without Analytics
                                        Cookies, we have less ability to monitor and improve our website.
                                    </Typography>
                                </Typography>

                                <Switch checked={checked} onChange={handleChange} color={'secondary'} />
                            </Box>
                        </Box>

                        <Box
                            display={'flex'}
                            flexDirection={largeScreen ? 'row' : 'column'}
                            justifyContent={'flex-end'}
                            width={'100%'}
                            mt={1}
                        >
                            <Button
                                variant="contained"
                                color="success"
                                className={'cookieBannerBtn'}
                                sx={{ backgroundColor: '#12c553' }}
                                onClick={() => handleCookieAccept()}
                            >
                                Accept All Cookies
                            </Button>
                            <Button
                                variant={'outlined'}
                                color="error"
                                className={'cookieBannerBtn'}
                                onClick={() => handleCookieReject()}
                                sx={{
                                    margin: '0 1rem',
                                    backgroundColor: '#e84117',
                                    color: 'white',
                                    '&:hover': { backgroundColor: '#9c280d', color: 'white' },
                                }}
                            >
                                Reject All
                            </Button>
                        </Box>
                    </Box>
                </Modal> */}
                <CookieSettings open={open} handleClose={handleClose} handleConsentChange={handleConsentChange} />
                <Typography id="modal-modal-title" variant="body2" component="p" color={'#ffffff'}>
                    We use cookies on our website to improve your experience, including cookies that are essential for website functionality
                    and cookies that allow us to understand how you interact with the site. By clicking <strong>Accept All Cookies,</strong>{' '}
                    you agree to allow all cookies we use to be set on your device. For more information on how we use cookies, see our{' '}
                    <a href={COOKIE_URL} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'white' }}>
                        Cookies Policy
                    </a>
                    . You can change your cookies selections at any time.
                </Typography>
                <Box
                    display={'flex'}
                    flexWrap={'wrap'}
                    flexDirection={largeScreen ? 'row' : 'column'}
                    justifyContent={'flex-end'}
                    width={'100%'}
                    mt={1}
                >
                    <Button
                        onClick={() => handleCookieAccept()}
                        className={'cookieBannerBtn'}
                        variant="contained"
                        color="success"
                        sx={{ backgroundColor: '#12c553' }}
                    >
                        Accept All Cookies
                    </Button>
                    <Button
                        onClick={() => handleCookieReject()}
                        variant={'outlined'}
                        color="error"
                        className={'cookieBannerBtn'}
                        sx={{
                            margin: '0 1rem',
                            backgroundColor: '#e84117',
                            color: 'white',
                            '&:hover': { backgroundColor: '#9c280d', color: 'white' },
                        }}
                    >
                        Reject All
                    </Button>
                    <Button
                        color="secondary"
                        className={'cookieBannerBtn'}
                        sx={{ backgroundColor: 'black', color: 'white' }}
                        onClick={handleOpen}
                    >
                        Cookie Settings
                    </Button>
                </Box>
            </Box>
        </>
    );
};
