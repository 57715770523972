import { Radio } from '@mui/material';

export const CustomRadio = (props) => {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<img src="/check.webp" alt="check" style={{ width: "16px", height: "15px" }} />}
            icon={<img src="/uncheck.webp" alt="check" style={{ width: "16px", height: "15px" }} />}
            {...props}
        />
    );
}