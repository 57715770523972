import React from "react";
import { Box, Typography } from "@mui/material";
import {
  officialEmail,
  docsURL,
  PRIVACY_URL,
  COOKIE_URL,
  TOS_URL,
  webURL,
} from "../../utils/constants";

const Footer = ({ darkBg }) => {
  const OuterContainer = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "start",
    px: 2,
    "@media (min-width: 600px)": {
      flexDirection: "row",
      alignItems: "end",
      px: 4,
    },
    "@media (min-width: 1100px)": {
      flexDirection: "row",
      alignItems: "center",
      px: 4,
    },
  };
  const InnerContainerStyle = {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    ".md-none": {
      display: "block",
    },
    "@media (min-width: 1100px)": {
      alignItems: "center",
      flexDirection: "row",
      ".md-none": {
        display: "none",
      },
    },
  };

  const PrivacyStyle = {
    marginLeft: "4px",
    fontFamily: "Formular",
    fontSize: "0.785rem",
    cursor: "pointer",
    "@media (min-width: 1100px)": {
      marginLeft: "8px",
    },
  };
  const ItemStyle = {
    marginLeft: "4px",
    fontSize: "0.785rem",
    fontFamily: "Formular",
    cursor: "pointer",
  };
  const RightLinkStyle = {
    mt: 3,
    mr: 2,
    color: "#7f7f7f",
    fontSize: "0.785rem",
    marginLeft: "8px",
    "@media (min-width: 1100px)": {
      mt: 0,
      ml: 0,
    },
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "end",
        backgroundColor: darkBg ? "#000000" : "#ffff",
      }}
    >
      <Box
        sx={{
          borderTop: darkBg ? "" : "1px solid #eee",
          py: 1,
          width: "100%",
        }}
      >
        <Box sx={OuterContainer}>
          <Box sx={InnerContainerStyle}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                color={darkBg ? "#F8F8F8" : "#7f7f7f"}
                sx={{ marginLeft: "8px" }}
                className="md-none"
              >
                •
              </Typography>
              <a
                href={PRIVACY_URL}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontSize: "inherit",
                  color: "inherit",
                  textDecoration: "inherit",
                }}
              >
                <Typography
                  color={darkBg ? "#F8F8F8" : "#7f7f7f"}
                  sx={PrivacyStyle}
                >
                  Privacy Policy
                </Typography>
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                color={darkBg ? "#F8F8F8" : "#7f7f7f"}
                style={{ marginLeft: "8px" }}
              >
                •
              </Typography>
              <a
                href={TOS_URL}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontSize: "inherit",
                  color: "inherit",
                  textDecoration: "inherit",
                }}
              >
                <Typography
                  component={"p"}
                  color={darkBg ? "#F8F8F8" : "#7f7f7f"}
                  style={ItemStyle}
                >
                  Terms Of Use
                </Typography>
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                color={darkBg ? "#F8F8F8" : "#7f7f7f"}
                style={{ marginLeft: "8px" }}
              >
                •
              </Typography>

              <a
                href={COOKIE_URL}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontSize: "inherit",
                  color: "inherit",
                  textDecoration: "inherit",
                }}
              >
                <Typography
                  color={darkBg ? "#F8F8F8" : "#7f7f7f"}
                  style={ItemStyle}
                >
                  Cookie Policy
                </Typography>
              </a>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                color={darkBg ? "#F8F8F8" : "#7f7f7f"}
                style={{ marginLeft: "8px" }}
              >
                •
              </Typography>
              <a
                href={docsURL}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontSize: "inherit",
                  color: "inherit",
                  textDecoration: "inherit",
                }}
              >
                <Typography
                  color={darkBg ? "#F8F8F8" : "#7f7f7f"}
                  style={ItemStyle}
                >
                  Documentation
                </Typography>
              </a>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                color={darkBg ? "#F8F8F8" : "#7f7f7f"}
                style={{ marginLeft: "8px" }}
              >
                •
              </Typography>
              <a
                href={`mailto:${officialEmail}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontSize: "inherit",
                  color: "inherit",
                  textDecoration: "inherit",
                }}
              >
                <Typography
                  color={darkBg ? "#F8F8F8" : "#7f7f7f"}
                  style={ItemStyle}
                >
                  Support
                </Typography>
              </a>
            </Box>
          </Box>
          <Box sx={RightLinkStyle}>
            Go back to
            <a
              href={webURL}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: "inherit",
                color: "inherit",
                textDecoration: "inherit",
              }}
            >
              <Box
                sx={{
                  textDecoration: "none",
                  display: "inline",
                  cursor: "pointer",
                  color: darkBg ? "#F8F8F8" : "#141414",
                }}
              >
                {" "}
                nvg8.io
              </Box>
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
