import React, { createContext, useContext, useState, useRef } from "react";
import {
  // AllCategories,
  AllCities,
  streetBaseMap,
  satelliteBaseMap,
} from "../../utils/constants";
import { useEffect } from "react";

const AppContext = createContext(null);

export const AppContextProvider = ({ children }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const searchBarRef = useRef(null);
  const featureCollection = React.useRef(null);
  const mapillaryCollection = React.useRef(null);
  const [baseMap, setBaseMap] = useState("streets-v11");
  const [satelliteView, setSatelliteView] = useState(false);
  const [currentImageId, setCurrentImageId] = useState(null);
  const [mapillaryFeature, setMapillaryFeature] = useState(null);
  const [toggleMapillary, setToggleMapillary] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [stats, setStats] = React.useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [tutorialSkippedOrCompleted, setTutorialSkippedOrCompleted] =
    useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityObject, setSelectedCityObject] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryObject, setSelectedCategoryObject] = useState("");
  const [showRasterLayer, setShowRasterLayer] = useState(true);
  const [showStreetLayer, setShowStreetLayer] = useState(true);
  const [allCategories, setAllCategories] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [loadingStats, setLoadingStats] = React.useState(false);

  useEffect(() => {
    if (selectedCity) {
      setSelectedCityObject(AllCities[selectedCity]);
    } else {
      setSelectedCityObject({});
    }
  }, [selectedCity]);

  const locateMe = () => {
    const locate = document.getElementsByClassName(
      "mapboxgl-ctrl-geolocate"
    )[0];
    if (locate) locate.click();
    // setLocateClicked((val) => !val);
  };

  const handleChangeBaseMap = () => {
    const currentMode = map.current.getStyle().metadata["mapbox:origin"];
    const toggleValue =
      currentMode === satelliteBaseMap ? streetBaseMap : satelliteBaseMap;
    setBaseMap(toggleValue);
    if (toggleValue) {
      map.current.setStyle("mapbox://styles/mapbox/" + toggleValue);
    }
  };
  return (
    <AppContext.Provider
      value={{
        mapContainer,
        map,
        searchBarRef,
        baseMap,
        setBaseMap,
        satelliteView,
        setSatelliteView,
        currentImageId,
        setCurrentImageId,
        mapillaryFeature,
        setMapillaryFeature,
        openEditor,
        setOpenEditor,
        suggestions,
        setSuggestions,
        notification,
        setNotification,
        tutorialSkippedOrCompleted,
        setTutorialSkippedOrCompleted,
        selectedCity,
        setSelectedCity,
        selectedCityObject,
        setSelectedCityObject,
        selectedCategory,
        setSelectedCategory,
        locateMe,
        handleChangeBaseMap,
        featureCollection,
        mapillaryCollection,
        toggleMapillary,
        setToggleMapillary,
        showRasterLayer,
        setShowRasterLayer,
        showStreetLayer,
        setShowStreetLayer,
        setAllCategories,
        allCategories,
        annotations,
        setAnnotations,
        selectedAnnotation,
        setSelectedAnnotation,
        stats,
        setStats,
        loadingStats,
        setLoadingStats,
        selectedCategoryObject,
        setSelectedCategoryObject,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useAppContext() {
  const context = useContext(AppContext);

  if (context === null) {
    throw new Error("useAppContext must be used within a WalletsProvider");
  }
  return context;
}
