import * as React from "react";

const SvgArrowDown = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="16" height="16" rx="4" fill="#C8503C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00001 3.5C6.00001 3.22386 6.22387 3 6.50001 3H9.50001C9.77616 3 10 3.22386 10 3.5V8.5H11.5C11.7022 8.5 11.8846 8.62182 11.962 8.80866C12.0393 8.9955 11.9966 9.21055 11.8536 9.35355L8.35357 12.8536C8.15831 13.0488 7.84172 13.0488 7.64646 12.8536L4.14646 9.35355C4.00346 9.21055 3.96068 8.9955 4.03807 8.80866C4.11547 8.62182 4.29778 8.5 4.50001 8.5H6.00001V3.5ZM7.00001 4V9C7.00001 9.27614 6.77616 9.5 6.50001 9.5H5.70712L8.00001 11.7929L10.2929 9.5H9.50001C9.22387 9.5 9.00001 9.27614 9.00001 9V4H7.00001Z"
      fill="#141414"
    />
  </svg>
);

export default SvgArrowDown;
