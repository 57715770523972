import {
  ArbitrumMainnet,
  ArbitrumTestnet,
  PolygonMainnet,
  PolygonTestnet,
} from './constants';
import { ethers } from 'ethers';
import { postMapEvent } from '../Api/api';

export const getBoundingBox = (map) => {
  const canvas = map.current.getCanvas();
  const w = canvas.width;
  const h = canvas.height;
  const cUL = map.current.unproject([0, 0]).toArray();
  const cUR = map.current.unproject([w, 0]).toArray();
  const cLR = map.current.unproject([w, h]).toArray();
  const cLL = map.current.unproject([0, h]).toArray();
  const coordinates = [[cUL, cUR, cLR, cLL, cUL]];
  return coordinates;
};

export const shortAddress = (str) => {
  if (str) {
    const len = str?.length;
    return str?.substr(0, 5) + '...' + str?.substr(len - 5, len);
  } else return '';
};

export const isPolygon = (id) => {
  if (id && (id === PolygonMainnet || id === PolygonTestnet)) return true;
};

export const getChainName = (id) => {
  if (id) {
    if (!isPolygon(id)) return 'Arbitrum';
    else return 'Polygon';
  } else return '';
};

export const addPolygonMainnet = async () => {
  if (window.ethereum)
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainName: 'Polygon Mainnet',
          chainId: ethers.utils.hexValue(PolygonMainnet),
          nativeCurrency: {
            name: 'MATIC',
            decimals: 18,
            symbol: 'MATIC',
          },
          rpcUrls: ['https://polygon-rpc.com/'],
        },
      ],
    });
};
export const addPolygonTestnet = async () => {
  if (window.ethereum)
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: ethers.utils.hexValue(PolygonTestnet),
          nativeCurrency: {
            name: 'MATIC',
            decimals: 18,
            symbol: 'MATIC',
          },
          rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
          chainName: 'Polygon Testnet',
        },
      ],
    });
};
export const addArbitrumTestnet = async () => {
  if (window.ethereum)
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: ethers.utils.hexValue(ArbitrumTestnet),
          nativeCurrency: {
            name: 'ETH',
            decimals: 18,
            symbol: 'ETH',
          },
          rpcUrls: ['https://goerli-rollup.arbitrum.io/rpc'],
          chainName: 'Arbitrum Testnet',
        },
      ],
    });
};
export const addArbitrumMainnet = async () => {
  if (window.ethereum)
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainName: 'Arbitrum One',
          chainId: ethers.utils.hexValue(ArbitrumMainnet),
          nativeCurrency: {
            name: 'ETH',
            decimals: 18,
            symbol: 'ETH',
          },
          rpcUrls: ['https://arb1.arbitrum.io/rpc'],
        },
      ],
    });
};

export const isAuthenticated = () => {
  const token = localStorage.getItem('jwtToken');
  return token ? true : false;
};

//zoom synchronously
export const syncZoom = async (map, level) => {
  const currentZoom = map.getZoom();
  if (currentZoom !== level)
    return await new Promise(async (res) => {
      await map.flyTo({
        zoom: level,
      });
      map.on('zoomend', function (e) {
        return res();
      });
    });
  else return;
};

//rotate map synchronously
export const syncRotate = async (map, bearing) => {
  const currentBearing = map.getBearing();
  if (currentBearing !== bearing)
    return await new Promise(async (res) => {
      await map.rotateTo(bearing);
      map.on('rotateend', function () {
        return res();
      });
    });
  else return;
};

//move location synchronously
export const syncMove = async (map, coordinates) => {
  return await new Promise(async (res) => {
    await map.flyTo({ center: coordinates });
    map.on('moveend', function () {
      return res();
    });
  });
};

export const addRasterLayerToUnClustered = async (
  map,
  polygons,
  addRasterLayer,
  type,
  triggeredBy,
  layer
) => {
  var currentZoom = map.current.getZoom();
  var center = map.current.getCenter();
  if (
    currentZoom &&
    currentZoom > 10 &&
    map.current.getLayer(`unclustered-point`)
  ) {
    var features = map.current.queryRenderedFeatures({
      layers: ['unclustered-point'],
    });
    var unique = Array.from(new Set(features.map(JSON.stringify))).map(
      JSON.parse
    );
    const polygonFeatures = [...polygons.current.features];
    unique.map((item) => {
      const id = item.properties.featureId;
      if (!map.current.getLayer(`tms-nvg8-layer-${id}`)) {
        addRasterLayer(map, item);
        const geometry = item?.properties?.geometry
          ? typeof item?.properties?.geometry === 'string'
            ? JSON.parse(item?.properties?.geometry)
            : item?.properties?.geometry
          : null;
        const polygonObj = {
          type: 'Feature',
          properties: item.properties,
          geometry: geometry,
        };
        polygonFeatures.push(polygonObj);
      }
    });
    polygons.current.features = polygonFeatures;
    if (map.current.getLayer(`nvg8-polygon-layer`)) {
      map.current.getSource('nvg8-polygon').setData(polygons.current);
    }
    //   if (type) {
    //     setTimeout(async () => {
    //       var _features = map.current.queryRenderedFeatures({ layers: ['nvg8-polygon-layer'] });
    //       var ids = _features.map((obj) => obj.properties.featureId);
    //       var mosaics = Array.from(new Set(ids));
    //       const event = { type, mosaics: [...mosaics], triggeredBy, center, layer }
    //       await postMapEvent(event)
    //     }, 2000) //set timeout so that polygon layer is being added meanwhile

    //   }
    // } else {
    //   if (type) {
    //     const event = { type, triggeredBy, center, layer }
    //     await postMapEvent(event)
    //   }
  }
};
export const removeRasterLayers = (map) => {
  map.current.getStyle().layers.map(function (layer) {
    if (layer.id.includes('tms-nvg8-layer')) map.current.removeLayer(layer.id);
  });
  const RasterSources = Object.keys(map.current.getStyle().sources);

  RasterSources.map((source) => {
    if (source.includes('tms-nvg8-source')) {
      map.current.removeSource(source);
    }
  });
};
export const removeDynamicGeoJsonLayers = (map) => {
  map.current.getStyle().layers.map(function (layer) {
    if (layer.id.includes('-dynamic-')) {
      map.current.removeLayer(layer.id);
    }
  });
  const DynamicSource = Object.keys(map.current.getStyle().sources);
  DynamicSource.map((source) => {
    if (source.includes('-dynamic-source')) {
      map.current.removeSource(source);
    }
  });
};



export const removeAnnotationsLayer = (map) => {
  map.current.getStyle().layers.map(function (layer) {
    if (layer.id.includes('annotations-data-layer')) map.current.removeLayer(layer.id);
  });
  const AnnotationSources = Object.keys(map.current.getStyle().sources);
  AnnotationSources.map((source) => {
    if (source.includes('annotations-data')) {
      map.current.removeSource(source);
    }
  });
};

export const removeAerialLayers = (map, polygons) => {
  map.current.removeLayer('cluster-count-1');
  map.current.removeLayer('unclustered-point');
  map.current.removeLayer('cluster-count');
  map.current.removeLayer('clusters');
  map.current.removeLayer('nvg8-polygon-layer');
  polygons.current = {
    type: 'FeatureCollection',
    features: [],
  };
  map.current.removeSource(`aerial`); // remove previous
  map.current.removeSource(`nvg8-polygon`); // remove previous
};

export const removeDashCamLayers = (map) => {
  map.current.removeLayer('mapillary-images');
  map.current.removeLayer('mapillary-sequences');
  map.current.removeSource(`mapillary`); // remove previous
  map.current.removeLayer('mapillary-data-layer');
  map.current.removeLayer('mapillary-cluster-count');
  map.current.removeSource(`mapillary-data`); // remove previous
};

export const debounce = (func, delay) => {
  let timerId;
  return function (...args) {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const getQueryParam = (param) => {
  var urlParams = new URLSearchParams(new URL(window.location.href).search);
  return urlParams.get(param);
};

export const formateDate = (capturedAtString) => {
  // Split the string into its components
  const [year, month, day, hour, minute, second, millisecond] = capturedAtString.split('_');

  // Create a Date object using the parsed components
  const formattedDate = new Date(year, month - 1, day, hour, minute, second, millisecond);
  return formattedDate
}

export const formatNumber = (num) => {
  if (num && typeof num === "number") {
    const absNum = Math.abs(num) // Get the absolute value of the number
    // Define the abbreviations for thousands, millions, billions, and so on
    const abbreviations = ["", "K", "M", "B", "T"]

    // Determine the scale of the number
    const scale = Math.floor(Math.log10(absNum) / 3)

    // Calculate the scaled value
    let _scale = scale < 0 ? 0 : scale > 4 ? 4 : scale
    const scaledValue = num / Math.pow(1000, _scale)
    // Format the scaled value with the appropriate abbreviation
    const formattedValue = `${Math.trunc(scaledValue * 100) / 100}${abbreviations[_scale]
      }`

    return formattedValue
  } else return 0
}