import * as React from 'react';
import { Box, } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useAppContext } from '../../lib/context/app-context';

export const MapControls = ({ }) => {
    const theme = useTheme()
    const { map, locateMe, handleChangeBaseMap } = useAppContext();
    const handleZoomIn = () => map?.current?.zoomIn()
    const handleZoomOut = () => map?.current?.zoomOut()

    const handleLocateMe = () => {
        locateMe()
    }

    return (
        <Box >
            <Box sx={{ bgcolor: "white", borderRadius: "5px", p: 0.5, mb: 1 }}>
                <Box onClick={handleLocateMe} sx={{ userSelect: "none", cursor: "pointer", height: "20px", width: "20px", display: 'flex', alignItems: "center", justifyContent: "center" }}>
                    <img src="/locate.webp" alt="mpd_mode" style={{ width: "12px" }} />
                </Box>
            </Box>
            <Box sx={{ bgcolor: "white", borderRadius: "5px", p: 0.5, mb: 1 }}>
                <Box onClick={handleChangeBaseMap} sx={{ userSelect: "none", cursor: "pointer", height: "20px", width: "20px", display: 'flex', alignItems: "center", justifyContent: "center" }}>
                    <img src="/map_mode.webp" alt="mpd_mode" style={{ width: "12px" }} />
                </Box>
            </Box>
            <Box sx={{ bgcolor: "white", fontWeight: "bold", fontSize: "18px", borderRadius: "5px", p: 0.5 }}>
                <Box onClick={handleZoomIn} sx={{ userSelect: "none", cursor: "pointer", height: "25px", width: "20px", display: 'flex', alignItems: "center", justifyContent: "center", borderBottom: `1px solid ${theme.palette.gray.light}`, }}>+</Box>
                <Box onClick={handleZoomOut} sx={{ userSelect: "none", cursor: "pointer", height: "25px", width: "20px", display: 'flex', alignItems: "center", justifyContent: "center", }}>-</Box>
            </Box>

        </Box >
    );
};
