import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Close from '@material-ui/icons/Close';
import { useAppContext } from '../../lib/context/app-context';

export const ToggleMappillary = ({ setToggleMapillary, close }) => {
    const { setCurrentImageId } = useAppContext()

    const handleCloseMappillary = () => {
        setToggleMapillary(false);
        setCurrentImageId(null);
    }

    return (
        close ? <div
            onClick={handleCloseMappillary}
            // onClick={() => setToggleMapillary(val => !val)}
            style={{
                background: "rgba(0,0,0,0.5)",
                padding: "0px",
                height: '60px',
                width: '60px',
                position: 'absolute',
                top: 0, right: 0,
                cursor: 'pointer',
                display: "flex", justifyContent: "end", alignItems: "start",
                zIndex: 5,
                clipPath: "polygon(0 0, 100% 0, 100% 100%)"

            }}>
            <Close style={{ color: "#ffff", fontSize: "25px", marginTop: "5px", marginRight: "2px" }} />
        </div> : <div
            onClick={() => setToggleMapillary(val => !val)}
            style={{
                background: "rgba(0,0,0,0.5)",
                padding: "0px",
                height: "70px", width: "70px",
                position: 'absolute',
                top: 0, left: 0,
                cursor: 'pointer',
                display: "flex", justifyContent: "start", alignItems: "start",
                zIndex: 5,
                clipPath: "polygon(0 0, 0% 100%, 100% 0)"
            }}>
            <ExpandLessIcon style={{ color: "#ffff", fontSize: "40px", rotate: "-45deg", }} />
        </div>
    )
}

export default ToggleMappillary