import React from 'react';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../utils/theme';
import Footer from './Footer';

function Layout({ children, darkBg = false }) {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
          }}
        >
          {children}
        </Box>
        <Footer darkBg={darkBg} />
      </Box>
    </ThemeProvider>
  );
}

export default Layout;
