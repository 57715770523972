import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Button } from "@material-ui/core";
import { uploadURL, } from "../../utils/constants";
import MUITooltip from '@mui/material/Tooltip';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { ExitToApp } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { setLogout } from "../../redux/reducers/auth";
import { useHistory } from "react-router-dom";
import { isProdOnly } from "../../lib/utils/isProduction";

export const RightMenu = ({ resetAllSettings, toggleChange, checkWindowSize, classes }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const handleLogout = () => {
        dispatch(setLogout());
        history.push("/")
    }
    return (
        <>
            <div className={classes.mapModeDiv}>
                <img alt="" src="/images/Nvg8-Logo.svg" className={classes.logo} />
                <div style={{ right: "35px", position: "absolute" }}>
                    <a
                        href={uploadURL}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                        <Button variant="contained" className={classes.contributeBTN}>
                            {checkWindowSize
                                ? "Contribute to Navigate Maps"
                                : "Contribute To NVG8"}
                        </Button>
                    </a>

                    <MUITooltip title={"Reset"} >
                        <span className="toggleButtonClass">
                            <ToggleButton
                                value="list"
                                aria-label="list"
                                onChange={resetAllSettings}
                                className={classes.toggleButton}
                            >
                                <RotateLeftIcon style={{ fontSize: "20px", color: "white" }} />
                            </ToggleButton>
                        </span>
                    </MUITooltip>
                </div>
            </div>
            <div className={classes.mapModeDiv2}>
                <div style={{ right: "35px", position: "absolute" }}>
                    <span className="toggleButtonClass">
                        <ToggleButton
                            value="list"
                            aria-label="list"
                            onChange={toggleChange}
                            className={classes.toggleButton}
                        >
                            <img alt="" src="/images/mapMode.webp" width={16} height={16} />
                        </ToggleButton>
                    </span>
                </div>
            </div>
            {!isProdOnly() && <div className={classes.mapModeDiv4}>
                <div style={{ right: "35px", position: "absolute" }}>
                    <MUITooltip title={"Logout"} >
                        <span className="toggleButtonClass">
                            <ToggleButton
                                value="list"
                                aria-label="list"
                                onChange={handleLogout}
                                className={classes.toggleButton}
                            >
                                <ExitToApp style={{ fontSize: "20px", color: "white" }} />
                            </ToggleButton>
                        </span>
                    </MUITooltip>
                </div>
            </div>}
        </>
    );
}

export default RightMenu;
