import { gatewayURL, shortAddress } from '../utils/constants';

export const addPolygonLayer = async (map, polygons) => {
  if (!map.getLayer(`nvg8-polygon-layer`)) {
    map.addSource('nvg8-polygon', {
      type: 'geojson',
      data: polygons,
    });

    // Add a layer showing the state polygons.
    map.addLayer({
      id: 'nvg8-polygon-layer',
      type: 'fill',
      source: 'nvg8-polygon',
      paint: {
        'fill-color': 'transparent',
        'fill-outline-color': 'transparent',
      },
    });

    // Change the cursor to a pointer when
    // the mouse is over the nvg8-polygon layer.
    map.on('mouseenter', 'nvg8-polygon-layer', () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    // Change the cursor back to a pointer
    // when it leaves the nvg8-polygon layer.
    map.on('mouseleave', 'nvg8-polygon-layer', () => {
      map.getCanvas().style.cursor = '';
    });
  }
};
export const addTilesLayerToMap = async (map, tilesData, polygons) => {
  addPolygonLayer(map.current, polygons);

  if (!map.current.getLayer(`clusters`)) {
    await map.current.addSource('aerial', {
      type: 'geojson',
      data: tilesData,
      // data: ClusterJson,
      cluster: true,
      clusterMaxZoom: 20, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    });

    await map.current.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'aerial',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': '#0769FC',
        'circle-stroke-color': 'transparent',
        'circle-stroke-width': 20,
        // 'circle-radius': [
        //   'step',
        //   ['get', 'point_count'],
        //   10,
        //   99,
        //   12,
        //   999,
        //   15,
        //   9999,
        //   20,
        // ],
        'circle-radius': 23,
      },
    });

    await map.current.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'aerial',
      filter: ['has', 'point_count'],
      paint: {
        "text-color": "#FFFFFF",
      },
      layout: {
        'icon-image': 'navigate',
        'icon-size': 0.3,
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
        'icon-anchor': 'bottom',
        'text-anchor': 'top',
      },
    });

    await map.current.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'aerial',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#0769FC',
        'circle-radius': 15,
        'circle-stroke-width': 0,
      },
    });

    await map.current.addLayer({
      id: 'cluster-count-1',
      type: 'symbol',
      source: 'aerial',
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': 'navigate',
        'icon-size': 0.25,
      },
    });

    // inspect a cluster on click
    map.current.on('click', 'clusters', (e) => {
      const features = map.current.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      });
      const clusterId = features[0].properties.cluster_id;
      map.current
        .getSource('aerial')
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.current.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    });

    map.current.on('mouseenter', 'clusters', () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });
    map.current.on('mouseleave', 'clusters', () => {
      map.current.getCanvas().style.cursor = '';
    });
    map.current.on('mouseenter', 'unclustered-point', () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });
    map.current.on('mouseleave', 'unclustered-point', () => {
      map.current.getCanvas().style.cursor = '';
    });

  }
};

export const addRasterLayer = (map, layer) => {
  const id = layer.properties.featureId;
  const bounds = layer.properties.bounds
    ? typeof layer.properties.bounds === 'string'
      ? JSON.parse(layer.properties.bounds)
      : layer.properties.bounds
    : [-180, -85.051129, 180, 85.051129]; //default

  if (map.current.getLayer('unclustered-point')) {
    if (layer && !map.current.getLayer(`tms-nvg8-layer-${id}`)) {
      let tmsURL = '';
      if (
        layer.properties.tms.includes('https://navmaps-dev.s3.amazonaws.com')
      ) {
        let tms = layer.properties.tms.split('/');
        tms = tms.length && tms[tms.length - 1];
        tmsURL = `${gatewayURL}/${tms}/{z}/{x}/{y}.png`;
      } else {
        tmsURL = `${layer.properties.tms}/{z}/{x}/{y}.png`;
      }
      map.current.addSource(`tms-nvg8-source-${id}`, {
        type: 'raster',
        scheme: 'tms',
        tiles: [tmsURL],
        tileSize: 256,
        bounds: [...bounds],
      });
      map.current.addLayer({
        id: `tms-nvg8-layer-${id}`,
        type: 'raster',
        source: `tms-nvg8-source-${id}`,
      });
      map.current.moveLayer(`tms-nvg8-layer-${id}`, 'unclustered-point');
      map.current.moveLayer(`tms-nvg8-layer-${id}`, 'clusters');
      map.current.moveLayer(`tms-nvg8-layer-${id}`, 'state-label');
      map.current.moveLayer(`tms-nvg8-layer-${id}`, 'road-label');
    }
  } else {
    if (layer && !map.current.getLayer(`tms-nvg8-layer-${id}`)) {
      let tmsURL = '';
      if (
        layer.properties.tms.includes('https://navmaps-dev.s3.amazonaws.com')
      ) {
        let tms = layer.properties.tms.split('/');
        tms = tms[tms.length - 1];
        tmsURL = `${gatewayURL}/${tms}/{z}/{x}/{y}.png`;
      } else {
        tmsURL = `${layer.properties.tms}/{z}/{x}/{y}.png`;
      }
      map.current.addSource(`tms-nvg8-source-${id}`, {
        type: 'raster',
        scheme: 'tms',
        tiles: [tmsURL],
        tileSize: 256,
        bounds: [...bounds],
      });
      map.current.addLayer({
        id: `tms-nvg8-layer-${id}`,
        type: 'raster',
        source: `tms-nvg8-source-${id}`,
      });
    }
  }
};

export const addMapillarySource = (mapRef, setImageId, setMapillaryFeature) => {
  const map = mapRef.current;
  map.addSource('mapillary', {
    type: 'vector',
    url: process.env.REACT_APP_TILESET_URL,
    minzoom: 8,
    maxzoom: 14,
  });
  map.addLayer({
    id: 'mapillary-sequences',
    type: 'line',
    source: 'mapillary',
    'source-layer': 'sequence',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#15C679',
      'line-width': 1,
    },
  });
  map.addLayer({
    id: 'mapillary-images',
    type: 'circle',
    source: 'mapillary',
    'source-layer': 'image',
    paint: {
      'circle-color': [
        'case',
        ['boolean', ['feature-state', 'clicked'], false],
        '#0769FC',// if selected
        '#05CB63',


      ],
      'circle-radius': 5,
      // 'circle-radius': [
      //   'interpolate',
      //   ['linear'],
      //   ['zoom'],
      //   17, 5,//Zoom level 0-17, radius 5 pixels
      //   18, 15,// Zoom level 18-22, radius 15 pixels
      // ],

    },
  });
  var featureId = null;

  map.on('mouseenter', 'mapillary-images', () => {
    map.getCanvas().style.cursor = 'pointer';
  });
  map.on('mouseleave', 'mapillary-images', () => {
    map.getCanvas().style.cursor = '';
  });
  map.on('click', 'mapillary-images', (e) => {
    // map.getCanvas().style.cursor = 'pointer';
    if (e.features.length > 0) {
      if (featureId) {
        map.removeFeatureState({
          source: 'mapillary',
          sourceLayer: 'image',
          id: featureId,
        });
      }
      featureId = e.features[0].id;
      map.setFeatureState(
        {
          source: 'mapillary',
          sourceLayer: 'image',
          id: featureId,
        },
        {
          clicked: true,
        }
      );
    }
    setMapillaryFeature(e.features[0].properties);
    setImageId(e.features[0].properties.id);
  });
};

export const addDataLayerToMapillary = async (mapRef, data) => {
  const map = mapRef.current;

  if (!map.getLayer(`mapillary-data-layer`)) {

    await map.addSource('mapillary-data', {
      type: 'geojson',
      data: data,
      cluster: true,
      clusterMaxZoom: 7, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    });

    await map.addLayer({
      id: 'mapillary-data-layer',
      type: 'circle',
      source: 'mapillary-data',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': '#15C679',
        'circle-stroke-color': 'transparent',
        'circle-stroke-width': 20,
        'circle-radius': 23,
      },
    });

    await map.addLayer({
      id: 'mapillary-cluster-count',
      type: 'symbol',
      source: 'mapillary-data',
      filter: ['has', 'point_count'],
      layout: {
        'icon-image': 'pointer',
        'icon-size': 0.3,
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
        'icon-anchor': 'bottom',
        'text-anchor': 'top',
      },
    });


    map.on('click', 'mapillary-data-layer', (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['mapillary-data-layer'],
      });
      const clusterId = features[0].properties.cluster_id;
      map
        .getSource('mapillary-data')
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    });
  }
};


export const addAnnotationsToMap = async (mapRef, data, setSelectedAnnotation) => {
  const map = mapRef.current;

  if (!map.getLayer(`annotations-data-layer`)) {

    await map.addSource('annotations-data', {
      type: 'geojson',
      data: data,
    });

    await map.addLayer({
      id: 'annotations-data-layer',
      type: 'symbol',
      source: 'annotations-data',
      layout: {
        // 'icon-image': 'blue-pointer',
        'icon-size': 0.2,
        'icon-image': [
          'match',
          ['get', 'clicked'],
          "true",
          'green-pointer',
          'blue-pointer'
        ]

      },
    });

    map.on('mouseenter', 'annotations-data-layer', () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    // Change the cursor back to a pointer
    // when it leaves the layer.
    map.on('mouseleave', 'annotations-data-layer', () => {
      map.getCanvas().style.cursor = '';
    });

    map.on('click', 'annotations-data-layer', function (e) {
      if (e.features.length > 0) {
        var clickedFeature = e.features[0];
        if (clickedFeature?.geometry?.coordinates) { map.flyTo({ center: clickedFeature?.geometry?.coordinates, zoom: 15 }) }

        const allFeatures = data.features
        if (allFeatures.length) {
          const prevClickedFeatureIndex = allFeatures.findIndex(item => item?.properties?.clicked === "true");
          if (prevClickedFeatureIndex > -1)
            allFeatures[prevClickedFeatureIndex] = { ...allFeatures[prevClickedFeatureIndex], properties: { ...allFeatures[prevClickedFeatureIndex].properties, clicked: "false" } }

          const clickedFeatureIndex = allFeatures.findIndex(item => item?.properties?.id === clickedFeature?.properties?.id);
          allFeatures[clickedFeatureIndex] = { ...allFeatures[clickedFeatureIndex], properties: { ...allFeatures[clickedFeatureIndex].properties, clicked: "true" } }
          setSelectedAnnotation(allFeatures[clickedFeatureIndex])
        }

        const updatedData = { ...data, features: [...allFeatures] };
        map.getSource('annotations-data').setData(updatedData);
      }
    });
  } else {
    map.getSource('annotations-data').setData(data);
  }
};
