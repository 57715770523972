import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  toggleButton: {
    width: "1.875rem",
    height: "1.875rem",
    background: "rgba(0, 0, 0, 0.7)",
    boxShadow: "0px 1px 24px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(24px)",
    borderRadius: "8px",
    padding: "10px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.7) !important",
    },
  },

  contributeBTN: {
    background: "#C8DC3C",
    borderRadius: "8px",
    color: "#141414",
    paddingTop: "5px",
    paddingBottom: "5px",
    textTransform: "capitalize",
    marginRight: "12px",
    fontFamily: "Formular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "160%",
    "&:hover": {
      background: "#C8DC3C",
      opacity: 0.9,
    },
  },
  mapModeDiv: {
    zIndex: "1",
    position: "absolute",
    top: "21px",
    left: 0,
    width: "100vw",
    display: "flex",
    justifyContent: "space-between",
  },
  mapModeDiv2: {
    zIndex: "1",
    position: "absolute",
    top: "55px",
    left: 0,
    width: "100vw",
    display: "flex",
    justifyContent: "space-between",
  },

  mapModeDiv3: {
    zIndex: "1",
    position: "absolute",
    top: "90px",
    left: 0,
    width: "100vw",
    display: "flex",
    justifyContent: "space-between",
  },
  mapModeDiv4: {
    zIndex: "1",
    position: "absolute",
    top: "215px",
    left: 0,
    width: "100vw",
    display: "flex",
    justifyContent: "space-between",
  },

  sideBarDivSM: {
    zIndex: "1",
    position: "absolute",
    top: "120px",
    left: "0px",
  },
  sideBarDivLG: {
    zIndex: "1",
    position: "absolute",
    top: "70px",
    left: "0px",
  },
  locationDetails: {
    marginTop: "70px",
    marginLeft: "60px",
    marginBottom: "80px",
    width: "360px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      marginTop: "280px",
      marginBottom: "80px"
    },
    background: "rgba(0, 0, 0, 1)",
    boxShadow: "0px 1px 24px rgba(0, 0, 0, 0.1)",
    filter: "blur(0px)",
    backdropFilter: "blur(2px)",
    borderRadius: "8px",
  },
  logo: {
    width: "7.25rem",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingTop: "8px",
    [theme.breakpoints.down("sm")]: {
      width: "5.25rem",
    },
  },
  sliderContainer: {
    position: "absolute",
    bottom: "8%",
    left: "0%",
    width: "100%",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
  },
  slider: {
    width: "609px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    background: "rgba(0, 0, 0, 0.7)",
    boxShadow: "0px 1px 24px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(2px)",
    borderRadius: "8px",
  },
  sliderContent: {
    padding: "14px",
  },
  closeIcon: {
    color: "white",
    cursor: "pointer",
    fontSize: "1rem",
    padding: "3px",
    "&:hover": { background: "#FFFFFF33", borderRadius: "50%" },
  },

  feedbackButtonContainer: {
    position: "absolute",
    bottom: 25,
    right: 20,
    zIndex: 2,
    [theme.breakpoints.down("xs")]: {
      right: 5, bottom: 35,

    },
  },
  feedbackOptionsContainer: {
    backgroundColor: "#14141480",
    borderRadius: "8px",
  },

  feedbackOption: {
    backgroundColor: "transparent",
    boxShadow: "none",
    color: "white",
    paddingTop: "5px",
    paddingBottom: "5px",
    textTransform: "capitalize",
    fontFamily: "Formular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "160%",
    width: "100%",
    height: "100%",
    "&:hover": {
      backgroundColor: "#14141490",
      boxShadow: "none",
    },
  },
  feedbackButton: {
    background: "#141414",
    borderRadius: "8px",
    color: "white",
    paddingTop: "5px",
    paddingBottom: "5px",
    textTransform: "capitalize",
    float: "right",
    fontFamily: "Formular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "160%",
    "&:hover": {
      background: "#141414",
      opacity: 0.9,
    },
  },
}));
