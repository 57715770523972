import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Pages/Login"
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { GTM_ID } from "./utils/constants";
import { PrivateRoute, PublicRoute } from "./components/AuthRoutes";
import { isProdOnly } from "./lib/utils/isProduction";
import GaInitScripts from './submodule/web3-login/components/GA/GaInitScripts';
import { AppContextProvider } from "./lib/context/app-context"

const AllRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    if (window.dataLayer)
      window.dataLayer.push({
        event: "pageview",
        page: location?.pathname,
      });
  }, [location]);
  return (isProdOnly() ?
    <Switch>
      <Route path="/" exact component={Home} />
      <Redirect to="/" />
    </Switch> :
    <Switch>
      <PublicRoute path="/login" exact component={Login} />
      <PrivateRoute path="/" exact component={Home} />
      <Redirect to="/" />
    </Switch>
  );
};

function App() {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <GaInitScripts />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AppContextProvider>
              <noscript>
                <iframe
                  src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
                  height="0"
                  width="0"
                  style={{ display: "none", visibility: "hidden" }}
                />
              </noscript>
              <AllRoutes />
            </AppContextProvider>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
