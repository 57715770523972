import authReducer from "./auth";
import tileReducer from "./tile";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth: authReducer,
  tile: tileReducer,
});

export default rootReducer;
