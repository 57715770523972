import * as React from "react";

const ChevronRight = (props) => (
  <svg
    width="4"
    height="7"
    viewBox="0 0 4 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.146447 0.146447C0.341709 -0.0488155 0.658291 -0.0488155 0.853553 0.146447L3.85355 3.14645C4.04882 3.34171 4.04882 3.65829 3.85355 3.85355L0.853553 6.85355C0.658291 7.04882 0.341709 7.04882 0.146447 6.85355C-0.0488155 6.65829 -0.0488155 6.34171 0.146447 6.14645L2.79289 3.5L0.146447 0.853553C-0.0488155 0.658291 -0.0488155 0.341709 0.146447 0.146447Z"
      fill="#7F7F7F"
    />
  </svg>
);

export default ChevronRight;
