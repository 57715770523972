import React, { useRef, useEffect, Suspense, useState, lazy } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { SearchBar } from '../../components';
import Footer from '../../components/Layout/Footer';
import { getQueryParam, removeAerialLayers, removeDashCamLayers, removeRasterLayers } from '../../utils/helper';
import { LeftMenu, RightMenu, Timeline } from '../../v2Components';
import { useStyles } from './Style';
import { useAppContext } from '../../lib/context/app-context';
import { getGeoJSON, getMapillaryFeatures } from '../../Api/api';
import MapillaryViewer from '../../components/Mapillary/Mapillary';
import {
    addRasterLayerToUnClustered, shortAddress, debounce,
} from '../../utils/helper';
import {
    backendURL, baseURL, gatewayURL, satelliteBaseMap,
} from '../../utils/constants';
import {
    BackDropChildren, BackDropCustom,
} from '../../components';
import {
    addTilesLayerToMap,
    addRasterLayer,
    addMapillarySource,
    addDataLayerToMapillary,
} from '../../components/AddTilesLayerToMap';

const ToggleMappillary = lazy(() =>
    import('../../components/Home/ToggleMappillary')
);

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN;

function Home({ showStreet }) {
    const classes = useStyles();
    const paramMosaicID = getQueryParam('mosaic_id');
    const rightMenuRef = useRef(null)
    const leftMenuRef = useRef(null)
    const showStreetRef = useRef(showStreet);
    const showRasterLayerRef = useRef(null)
    const showStreetLayerRef = useRef(null)
    const [active, setIsActive] = useState(true);
    // const mapContainer = useRef(null);
    const { mapContainer,
        map,
        setTutorialSkippedOrCompleted, searchBarRef,
        satelliteView, currentImageId, setCurrentImageId,
        mapillaryFeature, setMapillaryFeature, suggestions, setSuggestions, showRasterLayer,
        showStreetLayer, selectedCityObject, baseMap, setBaseMap, featureCollection,
        mapillaryCollection, toggleMapillary, setToggleMapillary, selectedCategory
    } = useAppContext()
    const polygons = useRef({
        type: 'FeatureCollection',
        features: [],
    });
    const searchBar = SearchBar(mapboxgl);
    const userLocationToggle = new mapboxgl.GeolocateControl({
        trackUserLocation: true,
    });
    const mapSettings = () => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            zoom: 2,
            center: [-74.5447, 40.6892], // New York
            style: `mapbox://styles/mapbox/${baseMap}`,
            transformRequest: (url, resourceType) => {
                if (
                    resourceType === 'Tile' &&
                    gatewayURL &&
                    url.startsWith(gatewayURL)
                ) {
                    return {
                        url: url,
                        headers: { 'x-api-key': process.env.REACT_APP_GATEWAY_API_KEY },
                    };
                }
            },
        });
        map.current.addControl(userLocationToggle, 'top-left');
        map.current.addControl(searchBar, 'top-left');
        map.current.on('load', async () => {
            searchBarRef.current = searchBar

            await map.current.loadImage('/pointer.webp', async (error, image) => {
                if (error) {
                    console.log(error);
                }

                // Add the image to the map style.
                if (!map.current.hasImage('pointer'))
                    await map.current.addImage('pointer', image);
            });

            await map.current.loadImage('/arrow.webp', async (error, image) => {
                if (error) {
                    console.log(error);
                }

                // Add the image to the map style.
                if (!map.current.hasImage('navigate'))
                    await map.current.addImage('navigate', image);
            });


            await map.current.loadImage('/blue-pointer.webp', async (error, image) => {
                if (error) {
                    console.log(error);
                }

                // Add the image to the map style.
                if (!map.current.hasImage('blue-pointer'))
                    await map.current.addImage('blue-pointer', image);
            });

            await map.current.loadImage('/green-pointer.webp', async (error, image) => {
                if (error) {
                    console.log(error);
                }

                // Add the image to the map style.
                if (!map.current.hasImage('green-pointer'))
                    await map.current.addImage('green-pointer', image);
            });

            if (process.env.REACT_APP_CLUSTER_URL) {
                setIsActive(true);
                await addTilesLayerToMap(
                    map,
                    process.env.REACT_APP_CLUSTER_URL,
                    polygons.current
                );
                // setIsActive(false);
            } else {
                setIsActive(true);
                const result = await getGeoJSON(`${baseURL}odm/cluster-json`);
                if (result?.success) {
                    const data = result?.data;
                    featureCollection.current = data;
                    await addTilesLayerToMap(map, data, polygons.current);
                }
                if (paramMosaicID) {
                    const mosaic_data = await getGeoJSON(
                        `${baseURL}mosaic/${paramMosaicID}`
                    );
                    console.log({ mosaic_data });
                    const coordinates = mosaic_data?.data?.coordinates;
                    if (coordinates && Array.isArray(coordinates)) {
                        await map.current.flyTo({
                            center: [...coordinates],
                            zoom: 20,
                        });
                    }
                }
                // setIsActive(false);
            }

            if (!map.current.getLayer(`mapillary-images`)) {
                addMapillarySource(map, setCurrentImageId, setMapillaryFeature);
                if (!mapillaryCollection.current) {
                    setIsActive(true);
                    const mapillaryResult = await getMapillaryFeatures(
                        `${backendURL}api/streetImagery/featureCollection`
                    );
                    if (mapillaryResult?.success) {
                        const data = mapillaryResult?.data;
                        mapillaryCollection.current = data;
                    }
                    setIsActive(false);
                }
                addDataLayerToMapillary(map, mapillaryCollection.current);
            }

            // map.current.on('click', `nvg8-polygon-layer`, (e) => {
            //     handleOpenDetails({
            //         properties: e?.features[0]?.properties,
            //         geometry: e?.features[0]?.geometry,
            //     });
            // });

            map.current.on('click', 'unclustered-point', (e) => {
                const zoom = map?.current?.getZoom();
                if (zoom < 15) {
                    map.current.flyTo({
                        center: e?.features[0]?.geometry.coordinates,
                        zoom: 15,
                    });
                } else {
                    map.current.flyTo({
                        center: e?.features[0]?.geometry.coordinates,
                    });
                }
                if (
                    !map.current.getLayer(
                        `tms-nvg8-layer-${e?.features[0]?.properties.featureId}`
                    )
                ) {
                    addRasterLayerToUnClustered(map, polygons, addRasterLayer);
                }
                // handleOpenDetails({
                //     properties: e?.features[0]?.properties,
                //     geometry: e?.features[0]?.geometry,
                // });
            });
            map.current.on('zoomend', function (e) {
                const triggeredBy = e.originalEvent ? 'manual' : 'cartographer';
                const layer = showStreetRef.current ? 'dashcam' : 'aerial';
                setTimeout(
                    () =>
                        addRasterLayerToUnClustered(
                            map,
                            polygons,
                            addRasterLayer,
                            'zoomend',
                            triggeredBy,
                            layer
                        ),
                    500
                );
            });

            map.current.on('dragend', (e) => {
                const triggeredBy = e.originalEvent ? 'manual' : 'cartographer';
                const layer = showStreetRef.current ? 'dashcam' : 'aerial';
                setTimeout(
                    () =>
                        addRasterLayerToUnClustered(
                            map,
                            polygons,
                            addRasterLayer,
                            'dragend',
                            triggeredBy,
                            layer
                        ),
                    500
                );
            });

            map.current.on('rotateend', rotateEndHandler);

            map.current.on('styledata', async () => {
                // if (!showStreetRef.current) {

                await map.current.loadImage('/pointer.webp', async (error, image) => {
                    if (error) {
                        console.log(error);
                    }

                    // Add the image to the map style.
                    if (!map.current.hasImage('pointer'))
                        await map.current.addImage('pointer', image);
                });

                await map.current.loadImage('/arrow.webp', async (error, image) => {
                    if (error) {
                        console.log(error);
                    }

                    // Add the image to the map style.
                    if (!map.current.hasImage('navigate'))
                        await map.current.addImage('navigate', image);
                });
                if (showRasterLayerRef.current) { addAerialLayer() }
                if (showStreetLayerRef.current) { addStreetLayer() }
            });
        });

        // map.current.on('load', async () => {
        //     searchBarRef.current = searchBar
        //     map.current.on('styledata', async () => {
        //     });
        // })
    };
    const satelliteViewHandler = async () => {
        if (map?.current && mapillaryFeature) {
            const _toggleVal = await map?.current?.getStyle()?.metadata?.[
                'mapbox:origin'
            ];

            setBaseMap(_toggleVal);
            if (satelliteView) {
                if (_toggleVal !== satelliteBaseMap)
                    map.current.setStyle(`mapbox://styles/mapbox/${satelliteBaseMap}`);
            } else {
                if (_toggleVal !== baseMap)
                    map.current.setStyle(`mapbox://styles/mapbox/${baseMap}`);
            }
        }
    };

    const handleRotateEnd = (e) => {
        const triggeredBy = e.originalEvent ? 'manual' : 'cartographer';
        const layer = showStreetRef.current ? 'dashcam' : 'aerial';

        setTimeout(
            () =>
                addRasterLayerToUnClustered(
                    map,
                    polygons,
                    addRasterLayer,
                    'rotateend',
                    triggeredBy,
                    layer
                ),
            500
        );
    };
    const rotateEndHandler = debounce(handleRotateEnd, 200);
    const searchHandler = (query) => {
        searchBarRef.current.query(query);
    }

    const getDateTime = (datetimeString) => {
        const dateObj = new Date(datetimeString);
        const date = dateObj.toLocaleDateString(); // gives the date
        const time = dateObj.toLocaleTimeString();
        return `${date} ${time}`;
    };
    const getDateTimeCapture = (datetimeString) => {
        const parts = datetimeString.split('_');

        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        const hour = parts[3];
        const minute = parts[4];
        const second = parts[5];
        const millisecond = parts[6];

        const date = `${year}-${month}-${day}`; // e.g. "2023-04-05"
        const time = `${hour}:${minute}:${second}.${millisecond}`; // e.g. "01:01:18.000"

        return `${date} ${time}`;
    };


    useEffect(() => {
        const tutorial_skipped = localStorage.getItem('tutorial_skipped');
        const tutorial_completed = localStorage.getItem('tutorial_completed');
        if (
            (tutorial_skipped && tutorial_skipped) ||
            (tutorial_completed && tutorial_completed)
        ) {
            setTutorialSkippedOrCompleted(true);
        }
        mapSettings();
    }, []);

    useEffect(() => {
        satelliteViewHandler();
    }, [satelliteView]);


    useEffect(() => {
        map?.current?.resize();
    }, []);

    const setParentHeight = () => {
        if (map.current) {
            if (mapContainer.current && rightMenuRef.current) {
                const absoluteHeight = rightMenuRef.current.offsetHeight;
                mapContainer.current.style.height = `${absoluteHeight}px`;
                if (leftMenuRef.current)
                    leftMenuRef.current.style.maxHeight = `${absoluteHeight}px`;
            }
            map.current.resize()
        }
    };

    useEffect(() => {

        setParentHeight(); // Set initial height

        // Attach an event listener to recalculate the height on window resize
        window.addEventListener('resize', setParentHeight);

        return () => {
            // Remove the event listener when the component unmounts
            window.removeEventListener('resize', setParentHeight);
        };
    }, []);
    useEffect(() => {
        if (!currentImageId) {
            setParentHeight()
        }
    }, [currentImageId])
    useEffect(() => {
        showStreetRef.current = showStreet;
    }, [showStreet]);

    useEffect(() => {
        map?.current?.resize();
    }, [toggleMapillary]);

    const addAerialLayer = async () => {
        if (!map.current.getLayer(`clusters`)) {
            if (process.env.REACT_APP_CLUSTER_URL) {
                if (!map.current.getLayer(`clusters`)) {
                    await addTilesLayerToMap(
                        map,
                        process.env.REACT_APP_CLUSTER_URL,
                        polygons.current
                    );
                    setTimeout(
                        () =>
                            addRasterLayerToUnClustered(map, polygons, addRasterLayer),
                        2000
                    );
                }
            } else {
                if (
                    featureCollection.current &&
                    !map.current.getLayer(`clusters`)
                ) {
                    await addTilesLayerToMap(
                        map,
                        featureCollection.current,
                        polygons.current
                    );
                    setTimeout(
                        () =>
                            addRasterLayerToUnClustered(map, polygons, addRasterLayer),
                        2000
                    );
                }
            }
        }
    }
    const addStreetLayer = async () => {
        if (!map.current.getLayer(`mapillary-images`)) {
            addMapillarySource(map, setCurrentImageId, setMapillaryFeature);
            if (!mapillaryCollection.current) {
                setIsActive(true);
                const mapillaryResult = await getMapillaryFeatures(
                    `${backendURL}api/streetImagery/featureCollection`
                );
                if (mapillaryResult?.success) {
                    const data = mapillaryResult?.data;
                    mapillaryCollection.current = data;
                }
                setIsActive(false);
            }
            addDataLayerToMapillary(map, mapillaryCollection.current);
        }
    }

    useEffect(() => {
        showRasterLayerRef.current = showRasterLayer
        if (showRasterLayer) {
            addAerialLayer()
        } else {
            if (map.current.getLayer(`clusters`)) {
                removeRasterLayers(map);
                removeAerialLayers(map, polygons);
            }
        }
    }, [showRasterLayer])
    useEffect(() => {
        showStreetLayerRef.current = showStreetLayer
        if (showStreetLayer) {
            addStreetLayer()
        } else {
            if (map.current.getLayer(`mapillary-images`)) {
                removeDashCamLayers(map);
                setCurrentImageId(null);
            }
        }
    }, [showStreetLayer])

    return (
        <div className="App">
            <Suspense fallback={<></>}>
                <BackDropCustom className={classes.backdrop} open={active}>
                    <BackDropChildren />
                </BackDropCustom>
                <div>
                    <div className={toggleMapillary ? 'main-map-viewer' : ''}>
                        <div
                            ref={mapContainer}
                            id={toggleMapillary ? 'streetViewer' : 'mapViewer'}
                            style={
                                toggleMapillary
                                    ? { position: 'relative' }
                                    : { minHeight: '94.5vh', height: 'auto' }
                            }
                        >
                            {toggleMapillary ? (
                                <>
                                    <ToggleMappillary setToggleMapillary={setToggleMapillary} />
                                    <ToggleMappillary setToggleMapillary={setToggleMapillary} close={true} />
                                </>
                            ) : (
                                <>
                                    <LeftMenu leftMenuRef={leftMenuRef} setParentHeight={setParentHeight} suggestions={suggestions} setSuggestions={setSuggestions} searchHandler={searchHandler} />
                                    <RightMenu rightMenuRef={rightMenuRef} setParentHeight={setParentHeight} />
                                    {selectedCategory && <Timeline />}
                                </>
                            )}
                        </div>
                    </div>
                    <div
                        style={
                            toggleMapillary
                                ? { position: 'absolute', bottom: 0, width: '100%' }
                                : {}
                        }
                    >
                        <Footer darkBg={false} />
                    </div>
                </div>

                {currentImageId && (
                    <>
                        <MapillaryViewer
                            imageId={currentImageId}
                            map={map.current}
                            setToggleMapillary={setToggleMapillary}
                            toggleMapillary={toggleMapillary}
                        />
                        <div
                            className={
                                toggleMapillary
                                    ? 'mapillary-attribution-option-map'
                                    : 'mapillary-attribution-option'
                            }
                        >
                            <div
                                style={{
                                    marginLeft: '5px',
                                    height: '20px',
                                    width: '100%',
                                    textAlign: 'left',
                                }}
                            >
                                {' '}
                                <div style={{ float: 'left' }}>
                                    {mapillaryFeature?.role?.toLowerCase() === 'whitelist'
                                        ? `Licensed by Navigate foundation. `
                                        : mapillaryFeature?.wallet_address
                                            ? `Image by ${shortAddress(
                                                mapillaryFeature?.wallet_address
                                            )}`
                                            : mapillaryFeature?.email
                                                ? `Image by ${mapillaryFeature?.email}`
                                                : `Licensed by Navigate foundation. `}{' '}
                                </div>
                                <div style={{ float: 'right' }}>
                                    Captured At {getDateTimeCapture(mapillaryFeature.captured_at)}
                                </div>
                                <br></br>
                            </div>
                            <div
                                style={{ position: 'absolute', bottom: '4px', left: '12px' }}
                            >
                                {mapillaryFeature.createdAT &&
                                    `Uploaded At ${getDateTime(mapillaryFeature.createdAT)}`}{' '}
                            </div>
                        </div>
                    </>
                )}
            </Suspense>
        </div>
    );
}

export default Home;
