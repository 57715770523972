import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  selectedTile: "",
};

const tileSlice = createSlice({
  name: "auth/tile",
  initialState,
  reducers: {
    setSelectedTile(state, action) {
      state.selectedTile = action.payload;
    },
  },
});

export const { setSelectedTile } = tileSlice.actions;

export default tileSlice.reducer;
