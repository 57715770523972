import * as React from 'react';

const SvgSequenceLogo = () => {
    return (
        <>
            <svg width="25" height="25" viewBox="0 0 396 318" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5_131)">
                    <g clip-path="url(#clip1_5_131)">
                        <path d="M0 67.5049L0 250.165C0 287.447 30.1402 317.67 67.32 317.67H328.68C365.86 317.67 396 287.447 396 250.165V67.5049C396 30.223 365.86 0 328.68 0H67.32C30.1402 0 0 30.223 0 67.5049Z" fill="#111111" />
                        <path d="M0 67.5049L0 250.165C0 287.447 30.1402 317.67 67.32 317.67H328.68C365.86 317.67 396 287.447 396 250.165V67.5049C396 30.223 365.86 0 328.68 0H67.32C30.1402 0 0 30.223 0 67.5049Z" fill="url(#paint0_linear_5_131)" />
                        <path d="M98.9999 79.4176C98.9999 68.4523 90.1351 59.5632 79.1999 59.5632C68.2647 59.5632 59.3999 68.4523 59.3999 79.4176C59.3999 90.3828 68.2647 99.272 79.1999 99.272C90.1351 99.272 98.9999 90.3828 98.9999 79.4176Z" fill="url(#paint1_linear_5_131)" />
                        <path d="M98.9999 79.4176C98.9999 68.4523 90.1351 59.5632 79.1999 59.5632C68.2647 59.5632 59.3999 68.4523 59.3999 79.4176C59.3999 90.3828 68.2647 99.272 79.1999 99.272C90.1351 99.272 98.9999 90.3828 98.9999 79.4176Z" fill="url(#paint2_linear_5_131)" />
                        <path d="M98.9999 79.4176C98.9999 68.4523 90.1351 59.5632 79.1999 59.5632C68.2647 59.5632 59.3999 68.4523 59.3999 79.4176C59.3999 90.3828 68.2647 99.272 79.1999 99.272C90.1351 99.272 98.9999 90.3828 98.9999 79.4176Z" fill="url(#paint3_linear_5_131)" />
                        <path d="M98.9999 238.126C98.9999 227.161 90.1351 218.272 79.1999 218.272C68.2647 218.272 59.3999 227.161 59.3999 238.126C59.3999 249.092 68.2647 257.981 79.1999 257.981C90.1351 257.981 98.9999 249.092 98.9999 238.126Z" fill="url(#paint4_linear_5_131)" />
                        <path d="M336.6 158.835C336.6 147.87 327.735 138.981 316.8 138.981C305.865 138.981 297 147.87 297 158.835C297 169.8 305.865 178.69 316.8 178.69C327.735 178.69 336.6 169.8 336.6 158.835Z" fill="url(#paint5_linear_5_131)" />
                        <path d="M336.6 158.835C336.6 147.87 327.735 138.981 316.8 138.981C305.865 138.981 297 147.87 297 158.835C297 169.8 305.865 178.69 316.8 178.69C327.735 178.69 336.6 169.8 336.6 158.835Z" fill="url(#paint6_linear_5_131)" />
                        <path d="M316.8 59.5632H158.4C147.465 59.5632 138.6 68.4523 138.6 79.4176C138.6 90.3828 147.465 99.272 158.4 99.272H316.8C327.735 99.272 336.6 90.3828 336.6 79.4176C336.6 68.4523 327.735 59.5632 316.8 59.5632Z" fill="url(#paint7_linear_5_131)" />
                        <path d="M316.8 218.272H158.4C147.465 218.272 138.6 227.161 138.6 238.126C138.6 249.092 147.465 257.981 158.4 257.981H316.8C327.735 257.981 336.6 249.092 336.6 238.126C336.6 227.161 327.735 218.272 316.8 218.272Z" fill="url(#paint8_linear_5_131)" />
                        <path d="M237.6 138.981H79.2C68.2648 138.981 59.4 147.87 59.4 158.835C59.4 169.8 68.2648 178.69 79.2 178.69H237.6C248.535 178.69 257.4 169.8 257.4 158.835C257.4 147.87 248.535 138.981 237.6 138.981Z" fill="url(#paint9_linear_5_131)" />
                    </g>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_5_131" x1="198" y1="4.05854e-05" x2="198" y2="318" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#1D273D" />
                        <stop offset="1" stop-color="#0D0F13" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_5_131" x1="65.5" y1="99" x2="92.5" y2="63" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#4462FE" />
                        <stop offset="1" stop-color="#7D69FA" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_5_131" x1="62.8799" y1="99.2912" x2="96.1377" y2="97.5911" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3757FD" />
                        <stop offset="1" stop-color="#6980FA" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_5_131" x1="62.8799" y1="99.2912" x2="96.1377" y2="97.5911" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#2447FF" />
                        <stop offset="1" stop-color="#6980FA" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_5_131" x1="65" y1="251.5" x2="91.5" y2="223.5" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#BC3EE6" />
                        <stop offset="1" stop-color="#D972F1" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_5_131" x1="305" y1="172" x2="329.5" y2="146" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#29BDFF" />
                        <stop offset="1" stop-color="#96E7FB" />
                    </linearGradient>
                    <linearGradient id="paint6_linear_5_131" x1="300.18" y1="178.418" x2="334.567" y2="176.772" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#23BBFF" />
                        <stop offset="1" stop-color="#85E7FF" />
                    </linearGradient>
                    <linearGradient id="paint7_linear_5_131" x1="154.5" y1="99" x2="317.5" y2="60" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#23BBFF" />
                        <stop offset="1" stop-color="#85E7FF" />
                    </linearGradient>
                    <linearGradient id="paint8_linear_5_131" x1="156" y1="258" x2="312.5" y2="218" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#2447FF" />
                        <stop offset="1" stop-color="#6980FA" />
                    </linearGradient>
                    <linearGradient id="paint9_linear_5_131" x1="86.0001" y1="179" x2="235.5" y2="139" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#6634FF" />
                        <stop offset="1" stop-color="#9C6DFF" />
                    </linearGradient>
                    <clipPath id="clip0_5_131">
                        <rect width="396" height="317.67" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_5_131">
                        <rect width="396" height="317.67" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}

export default SvgSequenceLogo;
