import React from "react";
export const BackDropChildren = () => {
  return (
    <div className={"overlayMainDiv"}>
      <div>
        <img
          src="/images/Nvg8-Logo.svg"
          style={{ width: "10.938rem", height: "2.275rem" }}
        />
      </div>
      <div>
        <img
          src="/favicon-avatar.svg"
          style={{ width: "8rem", height: "8rem", marginTop: "1rem", marginBottom: "1rem" }}
        />
      </div>
      <div>
        <p className={"loadingMapsText"}>Loading...</p>
      </div>
    </div>
  );
};
