export const GTM_ID = process.env.REACT_APP_GTM_ID ?? "";
export const baseURL = process.env.REACT_APP_BASE_URL ?? "";
export const backendURL = process.env.REACT_APP_BACKEND_URL ?? "";
export const uploadURL = process.env.REACT_APP_Upload_URL ?? "";
export const faqURL = process.env.REACT_APP_FAQ_URL ?? "";
export const twitterAccountURL = process.env.REACT_APP_Twitter_URL ?? "";
export const litepaperURL = process.env.REACT_APP_Lite_Paper_URL ?? "";
export const officialEmail = process.env.REACT_APP_Official_Email ?? "";
export const docsURL = process.env.REACT_APP_Docs_URL ?? "";
export const mapsURL = process.env.REACT_APP_Docs_URL
  ? `${process.env.REACT_APP_Docs_URL}navigate-maps`
  : "";
export const webURL = process.env.REACT_APP_Web_URL ?? "";
export const PRIVACY_URL = process.env.REACT_APP_PRIVACY_URL ?? "";
export const COOKIE_URL = process.env.REACT_APP_COOKIE_URL ?? "";
export const TOS_URL = process.env.REACT_APP_TOS_URL ?? "";
export const mapillaryURL = process.env.REACT_APP_MAPILLARY_URL ?? "";
export const gatewayURL = process.env.REACT_APP_API_GATEWAY_BASE_URL ?? "";
export const mapFormURL = `https://form.jotform.com/222976161777165`;

export const PENDING = "Pending";
export const ACCEPTED = "Accepted";
export const REJECTED = "Rejected";
export const PARTIAL_ACCEPTED = "Partial Accepted";
export const INTEGRATION_TYPE = "NAV_MAPS";



export const PolygonMainnet = 137;
export const PolygonTestnet = 80001;
export const ArbitrumMainnet = 42161;
export const ArbitrumTestnet = 421613;



export const TutorialSteps = [
  {
    target: ".mapboxgl-ctrl",
    placement: "center",
    title: "Welcome to Navigate Maps!",
    content:
      "Hi there! Let's walk you through the app. In just a few steps, you'll be Navigating like a pro.",
  },
  {
    target: ".mapboxgl-ctrl-geocoder",
    title: "Search for Places",
    content:
      "Here, you can search for cities, neighborhoods, buildings, and more!",
  },
  {
    target: "#layerButtonId",
    title: "Switch Layers",
    placement: "right",
    content: "Layers allow you to view or hide certain elements on the map.",
  },
  {
    target: "#locateBtn",
    title: "LOCATE ME!",
    placement: "right",
    content:
      "Locate yourself on the map and explore the Navigate content nearby.",
  },
  {
    target: ".toggleButtonClass",
    title: "SWITCH THEME",
    placement: "bottom",
    content: "Click here to switch to satellite view at any time",
  },
  {
    target: "#feedbackButton",
    title: "SEND FEEDBACK",
    placement: "top",
    content:
      "We want to hear from you! So before you log out, don’t forget to share your thoughts and feedback on the app.",
  },
];

export const AllCities = {
  austin: {
    label: "Austin, Tx", value: "austin", coordinates: [-97.7437, 30.271129]
  },
  chicago: {
    label: "Chicago, Il ( Coming Soon )", value: "chicago", coordinates: [-87.624421, 41.875562]
  },
  paris: {
    label: "Paris, Fr ( Coming Soon )", value: "paris", coordinates: [2.3483915, 48.8534951]
  },
}

export const satelliteBaseMap = "satellite-streets-v11"
export const streetBaseMap = "streets-v11"


export const AustinPolygon = {
  "geometry": {
    "coordinates": [
      [
        [
          -97.88406931596622,
          30.191045011657238
        ],
        [
          -97.87645043281785,
          30.024636439467997
        ],
        [
          -97.67839084556554,
          30.085351664044623
        ],
        [
          -97.62846165166366,
          30.191045011657238
        ],
        [
          -97.57778724591292,
          30.29276436074703
        ],
        [
          -97.58076809331014,
          30.393092730443897
        ],
        [
          -97.68211690481162,
          30.425870072389344
        ],
        [
          -97.78793698740942,
          30.417516108817324
        ],
        [
          -97.79389868220343,
          30.303059181486447
        ],
        [
          -97.89003101076023,
          30.25350544430384
        ],
        [
          -97.88406931596622,
          30.191045011657238
        ]
      ]
    ],
    "type": "Polygon"
  }
}




