import * as React from 'react';
import { Box } from '@mui/material';

export const CategoriesIcon = ({ bgcolor, size }) => {
    return (
        <Box sx={{
            width: size ?? "25px",
            height: size ?? "25px",
            borderRadius: "100%",
            bgcolor: bgcolor ?? "#0D1F23",
            mr: 2,
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <img src="/categories.webp" alt="navigation-icon" style={{ width: "12px" }} />
        </Box>
    );
}
