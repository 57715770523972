import React, { lazy, Suspense } from "react";
import { makeStyles, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";

const TileDetails = lazy(() => import('./TileDetails'));
const Stats = lazy(() => import('./Stats'));


const useStyles = makeStyles((theme) => ({
  closeIcon: {
    color: "white",
    cursor: "pointer",
    fontSize: "1rem",
    padding: "3px",
    "&:hover": { background: "#FFFFFF33", borderRadius: "50%" },
  }
}));

export function LocationDetails({
  closeClicked,
  map,
  toggleSlider,
  handleGoToNext,
}) {
  const classes = useStyles();
  const [tab, setTab] = React.useState("image");
  const selectedTile = useSelector((state) => state.tile.selectedTile);
  const isAdmin = localStorage.getItem("Role") === "ADMIN" ? true : false;

  const closeNow = () => {
    closeClicked(true);
  };
  return (
    <>
      <Suspense fallback={<></>}>
        <Box style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              marginTop: "10px",
            }}
          >
            <Typography
              component={"h3"}
              style={{ fontSize: "20px", lineHeight: "32px", color: "#FFFFFF" }}
              className="font-medium"
            >
              Image Details
            </Typography>
            <CloseIcon className={classes.closeIcon} onClick={closeNow} />
          </div>{" "}
          {isAdmin && selectedTile?.properties?.oam_id && (
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Typography
                component={"p"}
                className={classes.locationSubHeading}
                style={{ fontSize: "12px", color: "#FFFFFF" }}
              >
                <span style={{ color: "#BEBEBE" }}>ID </span>{" "}
                <span style={{ color: "#ffff" }}>
                  {selectedTile?.properties?.oam_id}
                </span>{" "}
              </Typography>
            </div>
          )}
          {isAdmin && selectedTile?.properties?.tile && (
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Typography
                component={"p"}
                className={classes.locationSubHeading}
                style={{ fontSize: "12px", color: "#FFFFFF" }}
              >
                <span style={{ color: "#BEBEBE" }}>Title </span>{" "}
                <span style={{ color: "#ffff" }}>
                  {selectedTile?.properties?.title}
                </span>{" "}
              </Typography>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography
              component={"p"}
              className={classes.locationSubHeading}
              style={{ fontSize: "12px", color: "#FFFFFF" }}
            >
              <span style={{ color: "#BEBEBE" }}>Updated on </span>{" "}
              <span style={{ color: "#ffff" }}>
                {selectedTile?.properties?.acquisition_start ||
                  selectedTile?.properties?.uploaded_at
                  ? moment(
                    selectedTile?.properties?.acquisition_start
                      ? selectedTile?.properties?.acquisition_start
                      : selectedTile?.properties?.uploaded_at
                  ).format("MMM Do, YYYY")
                  : "N/A"}
              </span>{" "}
            </Typography>
          </div>
          {tab === "image" ? (
            <TileDetails
              map={map}
              toggleSlider={toggleSlider}
              handleGoToNext={handleGoToNext}
            />
          ) : (
            <Stats />
          )}
        </Box>
      </Suspense>
    </>
  );
}
