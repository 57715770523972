import * as React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useAppContext } from '../../lib/context/app-context';
import { getStats } from '../../Api/api';
import { AustinPolygon } from '../../utils/constants';
import { Skeleton } from '@mui/material';
import { formatNumber } from "../../utils/helper"


export const Statistics = ({ }) => {
    const theme = useTheme()
    const { selectedCity, selectedCityObject, stats, setStats, loadingStats, setLoadingStats } = useAppContext();


    const handleFetchStats = async (geometry) => {
        setLoadingStats(true)
        const response = await getStats(geometry)
        if (response?.success)
            setStats(response?.data)
        setLoadingStats(false)

    }
    React.useEffect(() => {
        if (selectedCity === "austin") {
            handleFetchStats(AustinPolygon)
        } else
            handleFetchStats()
    }, [selectedCity,])

    const displayValue = (value) => {
        return loadingStats ?
            <Skeleton variant="rectangular" width={50} height={15} sx={{ mt: 1 }} />
            :
            <Typography sx={{ fontSize: "24px", fontWeight: "bold", mt: 1 }}  >
                {value}
            </Typography>

    }

    return (
        <Box sx={{ pt: 2, display: "flex", flexDirection: "column", height: "100%" }}>
            <Box sx={{ px: 3, }} >
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'start', pb: 3, borderBottom: `1px solid ${theme.palette.gray.light}} ` }}>
                    <Typography sx={{ fontSize: "40px", fontWeight: "bold" }}  >{selectedCityObject?.label?.split(",")?.length ? selectedCityObject?.label?.split(",")[0] : "World"}</Typography>
                    <Typography sx={{ fontSize: "16px", color: theme.palette.gray.main }}  >{selectedCity === "austin" ? "Texas, United States" : "Select City or Region"}</Typography>
                </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", px: 3, alignItems: 'start', py: 3, }}>
                <Typography sx={{ fontSize: "12px", color: theme.palette.gray.main }} variant='div' >
                    The data captured by {" "}
                    <Typography sx={{ fontSize: "12px", color: theme.palette.primary.main }} variant='span' >
                        Navigate
                    </Typography>{" "}
                    {selectedCity &&
                        <> for{" "}
                            <Typography sx={{ fontSize: "12px", color: theme.palette.black.main, textDecoration: "underline" }} variant='span' >
                                Austin, TX
                            </Typography>
                        </>}.
                </Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", px: 3, alignItems: 'start', bgcolor: theme?.palette?.theme_white?.light, flexGrow: 1 }}>

                {!selectedCity && <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", py: 3, width: "100%", borderBottom: `1px solid ${theme.palette.gray.light}} ` }}>
                    <Typography sx={{ fontSize: "12px", color: theme.palette.gray.main }} variant='div' >
                        Covered Cities
                    </Typography>
                    {displayValue(stats?.distinctCityCount ?? 0)}
                </Box>}
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", py: 3, width: "100%", borderBottom: `1px solid ${theme.palette.gray.light}} ` }}>
                    <Typography sx={{ fontSize: "12px", color: theme.palette.gray.main }} variant='div' >
                        Total Street Coverage
                    </Typography>
                    {displayValue(`${stats?.street?.distanceCovered ? formatNumber(Number(stats?.street?.distanceCovered.toFixed(2))) : 0} km`)}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", py: 3, width: "100%", borderBottom: `1px solid ${theme.palette.gray.light}} ` }}>
                    <Typography sx={{ fontSize: "12px", color: theme.palette.gray.main }} variant='div' >
                        Total Street Images
                    </Typography>
                    {displayValue(formatNumber(stats?.street?.totalImages ?? 0))}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", py: 3, width: "100%", borderBottom: `1px solid ${theme.palette.gray.light}} ` }}>
                    <Typography sx={{ fontSize: "12px", color: theme.palette.gray.main }} variant='div' >
                        Total Aerial Images
                    </Typography>
                    {displayValue(formatNumber(stats?.aerial?.totalImages ?? 0))}
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", py: 3, width: "100%", borderBottom: `1px solid ${theme.palette.gray.light}} ` }}>
                    <Typography sx={{ fontSize: "12px", color: theme.palette.gray.main }} variant='div' >
                        Total Aerial Coverage
                    </Typography>
                    {displayValue(`${stats?.aerial?.totalArea ? formatNumber(Number(stats?.aerial?.totalArea.toFixed(2))) : 0} sq. km`)}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", py: 3, width: "100%", }}>
                    <Typography sx={{ fontSize: "12px", color: theme.palette.gray.main }} variant='div' >
                        Total Datasets
                    </Typography>
                    {displayValue(formatNumber(stats?.totalDatasets ?? 0))}
                </Box>
            </Box>
        </Box>
    );
};
