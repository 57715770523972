import * as React from "react";

const SvgNvg8Logo = (props) => (
  <svg width="1084" height="183" viewBox="0 0 1084 183" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M120.316 28.2045C94.7966 16.7465 63.7945 21.5136 42.8652 42.4429C21.9206 63.3875 17.162 94.4196 28.652 119.949C31.0999 125.388 28.6751 131.782 23.236 134.23C17.7969 136.678 11.4031 134.253 8.95512 128.814C-6.09664 95.3707 0.0946859 54.6667 27.5919 27.1695C55.0689 -0.307584 95.7336 -6.50992 129.163 8.49974C134.604 10.9429 137.035 17.3345 134.592 22.7758C132.149 28.2171 125.757 30.6476 120.316 28.2045ZM160.565 48.7351C166.005 46.289 172.398 48.716 174.844 54.156C189.879 87.5939 183.684 128.283 156.194 155.772C128.711 183.255 88.034 189.454 54.6003 174.431C49.1596 171.987 46.7309 165.594 49.1755 160.154C51.6202 154.713 58.0124 152.284 63.4531 154.729C88.9754 166.197 119.987 161.433 140.921 140.498C161.859 119.56 166.621 88.5395 155.144 63.0141C152.698 57.5741 155.125 51.1812 160.565 48.7351Z" fill="#FFFBF5" />
    <path d="M117.131 54.1253C123.991 51.975 130.439 58.4232 128.289 65.2838L107.337 132.133C104.703 140.537 92.7709 140.424 90.2964 131.972L82.6387 105.818C81.7859 102.906 79.5084 100.628 76.596 99.7757L50.4421 92.118C41.9906 89.6435 41.8779 77.7116 50.2811 75.0778L117.131 54.1253Z" fill="#FFFBF5" />
    <path d="M354.416 117.358C349.103 117.358 345.954 114.192 345.954 108.849V78.1761C345.954 54.4294 330.408 38.9941 306.795 38.9941C293.611 38.9941 282.591 44.1392 274.916 53.8358V41.3688H249.335V142.292H274.916V86.8833C274.916 73.031 284.362 63.9281 298.53 63.9281C311.714 63.9281 320.373 72.8331 320.373 86.4875V114.39C320.373 132.794 330.212 142.292 349.299 142.292H356.58V117.358H354.416Z" fill="#FFFBF5" />
    <path d="M481.17 117.358C475.66 117.358 472.708 114.192 472.708 108.849V41.3688H447.127V54.6273C439.256 44.9308 428.039 38.9941 414.461 38.9941C386.518 38.9941 365.463 61.7513 365.463 91.8305C365.463 121.91 386.518 144.667 414.461 144.667C429.023 144.667 441.027 137.741 448.898 126.461C452.637 136.949 461.689 142.292 475.857 142.292H483.138V117.358H481.17ZM419.381 119.733C403.638 119.733 391.832 107.662 391.832 91.8305C391.832 75.9994 403.638 63.9281 419.381 63.9281C435.32 63.9281 447.127 75.9994 447.127 91.8305C447.127 107.662 435.32 119.733 419.381 119.733Z" fill="#FFFBF5" />
    <path d="M585.586 41.3688H559.415C561.579 51.8569 559.218 63.9281 553.708 79.1656L541.508 110.234L515.336 41.3688H486.212L527.733 142.292H554.298L578.306 84.5086C585.586 67.0944 587.948 52.8463 585.586 41.3688Z" fill="#FFFBF5" />
    <path d="M615.917 32.7292C624.969 32.7292 632.053 25.8031 632.053 16.7002C632.053 7.59727 624.969 0.671143 615.917 0.671143C606.865 0.671143 599.781 7.59727 599.781 16.7002C599.781 25.8031 606.865 32.7292 615.917 32.7292ZM603.126 142.292H628.707V41.3688H603.126V142.292Z" fill="#FFFBF5" />
    <path d="M729.225 41.3688V54.0337C721.354 44.7329 710.138 38.9941 696.756 38.9941C668.814 38.9941 647.758 61.1577 647.758 90.2474C647.758 119.535 668.814 141.699 696.756 141.699C710.138 141.699 721.354 135.96 729.225 126.659V131.21C729.225 147.833 718.993 158.717 703.25 158.717C692.821 158.717 683.769 154.363 679.833 147.437H653.465C659.762 169.007 678.062 182.266 703.25 182.266C734.342 182.266 754.807 162.081 754.807 131.408V41.3688H729.225ZM701.676 118.348C685.934 118.348 674.127 106.276 674.127 90.2474C674.127 74.4162 685.934 62.345 701.676 62.345C717.615 62.345 729.225 74.4162 729.225 90.2474C729.225 106.276 717.615 118.348 701.676 118.348Z" fill="#FFFBF5" />
    <path d="M888.759 117.358C883.249 117.358 880.297 114.192 880.297 108.849V41.3688H854.716V54.6273C846.844 44.9308 835.628 38.9941 822.05 38.9941C794.107 38.9941 773.052 61.7513 773.052 91.8305C773.052 121.91 794.107 144.667 822.05 144.667C836.612 144.667 848.616 137.741 856.487 126.461C860.226 136.949 869.277 142.292 883.446 142.292H890.727V117.358H888.759ZM826.97 119.733C811.227 119.733 799.42 107.662 799.42 91.8305C799.42 75.9994 811.227 63.9281 826.97 63.9281C842.909 63.9281 854.716 75.9994 854.716 91.8305C854.716 107.662 842.909 119.733 826.97 119.733Z" fill="#FFFBF5" />
    <path d="M964.239 64.9176V41.3688H941.019V14.4559H916.815V33.2553C916.815 38.5983 914.06 41.3688 908.747 41.3688H899.499V64.9176H915.438V110.432C915.438 131.21 927.441 142.292 950.465 142.292H964.239V118.743H954.203C945.939 118.743 941.019 113.994 941.019 106.078V64.9176H964.239Z" fill="#FFFBF5" />
    <path d="M1083.42 92.0284C1083.42 61.7514 1060.2 38.9941 1029.51 38.9941C998.808 38.9941 975.588 61.7513 975.588 91.8305C975.588 121.91 998.808 144.667 1029.9 144.667C1054.3 144.667 1074.17 131.013 1081.85 110.828H1054.1C1049.77 117.358 1040.72 121.91 1030.29 121.91C1016.52 121.91 1006.29 113.598 1002.94 100.735H1082.64C1083.23 98.3608 1083.42 94.6009 1083.42 92.0284ZM1029.51 61.5535C1042.1 61.5535 1052.53 69.6669 1055.87 81.936H1003.33C1006.68 69.6669 1016.72 61.5535 1029.51 61.5535Z" fill="#FFFBF5" />
  </svg>

);

export default SvgNvg8Logo;
