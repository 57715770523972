import * as React from 'react';
import { Box, Grid, Slide } from '@mui/material';
import { useTheme } from '@emotion/react';
import { MapControls } from './Controls';
import { Statistics } from './Statistics';
import { useAppContext } from '../../lib/context/app-context';
import { ToggleButton } from '../Common';

export default function StatisticsContainer({ showMenu, handleToggleMenu, }) {
    const theme = useTheme()
    // const { selectedCity } = useAppContext()
    return (
        <Slide direction='left' in={showMenu} >
            <Box sx={{ bgcolor: theme?.palette?.theme_white?.light, width: "100%", height: '100%', backdropFilter: "blur(12px)", position: "relative" }}>
                <ToggleButton handleClick={handleToggleMenu} position={{ top: "50%", left: 8 }} />
                <Statistics />
            </Box>
        </Slide>
    );
}


export const RightMenu = ({ rightMenuRef, setParentHeight }) => {
    const { currentImageId, selectedCity } = useAppContext()
    const [showMenu, setShowMenu] = React.useState(true);

    const handleToggleMenu = () => {
        if (!currentImageId)
            setShowMenu((prev) => !prev);
    };
    React.useEffect(() => {
        if (currentImageId)
            setShowMenu(false);
    }, [currentImageId])
    React.useEffect(() => {
        if (showMenu) {
            setParentHeight()
        }
    }, [showMenu, selectedCity])
    return (

        <Box
            ref={rightMenuRef}
            sx={{
                position: 'absolute',
                right: '0px',
                top: '0px',
                width: !showMenu ? "50px" : '380px',
                zIndex: 10,
            }}
        >
            <Grid container>
                <Grid item xs={showMenu ? 2 : 11} sx={{ display: "flex", justifyContent: showMenu ? "center" : "center", pt: 3 }}>
                    <MapControls />
                </Grid>
                {showMenu ?
                    <Grid item xs={10} sx={{ minHeight: "94.5vh", }}>
                        <StatisticsContainer showMenu={showMenu} handleToggleMenu={handleToggleMenu} />
                    </Grid> :
                    <Grid item xs={1} sx={{ minHeight: "94.5vh", }}>
                        <ToggleButton shadow={true} handleClick={handleToggleMenu} position={{ top: "50%", right: 25 }} />
                    </Grid>
                }
            </Grid>
        </Box >
    );
};
