import * as React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';

export const ToggleButton = ({ handleClick, position, shadow }) => {
    const theme = useTheme()

    return (
        <Box sx={{ ...position, boxShadow: shadow ? "0px 1px 3px rgba(0,0,0,0.5)" : "none", cursor: "pointer", position: "absolute", height: "30px", width: "5px", borderRadius: "50px", bgcolor: "#888888", }} onClick={handleClick} />
    );
};
