import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: { main: "#0769FC" },
    secondary: {
      main: "#0769FC",
    },
    black: {
      main: "#0D1F23",
      contrastText: 'white',
    },
    theme_white: {
      main: "#FFFFFF",
      light: "#FFFFFF80",
      default: "#FFFFFF"
    },
    gray: {
      main: "#0D1F2340",
      light: "#0D1F231A",
    },
  },
  typography: {
    fontFamily: ["Aeonik-Regular", "sans-serif"].join(","),
    subtitle2: {
      fontFamily: "Aeonik-Medium, sans-serif",
      fontSize: "1rem",
    },
    button: {
      textTransform: "none",
    },
  },
});

export default theme;


