import * as React from "react";

const SvgUploadIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={props?.fill ?? "none"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66675 7.99999C2.66675 7.63181 2.96522 7.33333 3.33341 7.33333H12.6667C13.0349 7.33333 13.3334 7.63181 13.3334 7.99999C13.3334 8.36818 13.0349 8.66666 12.6667 8.66666H3.33341C2.96522 8.66666 2.66675 8.36818 2.66675 7.99999Z"
      fill={props?.fill ?? "#141414"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.52851 2.86193C7.78886 2.60158 8.21097 2.60158 8.47132 2.86193L13.138 7.5286C13.3983 7.78895 13.3983 8.21106 13.138 8.47141L8.47132 13.1381C8.21097 13.3984 7.78886 13.3984 7.52851 13.1381C7.26816 12.8777 7.26816 12.4556 7.52851 12.1953L11.7238 8L7.52851 3.80474C7.26816 3.54439 7.26816 3.12228 7.52851 2.86193Z"
      fill={props?.fill ?? "#141414"}
    />
  </svg>
);

export default SvgUploadIcon;
